import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');
let env_check = true;
function flattenObject(ob: any) {
	var toReturn: any = {};

	for (var i in ob) {
		if (!ob.hasOwnProperty(i)) continue;

		if (typeof ob[i] == 'object' && ob[i] !== null) {
			var flatObject = flattenObject(ob[i]);
			for (var x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue;

				toReturn[i + '.' + x] = flatObject[x];
			}
		} else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
}
let actions = {
	identify: (id: any) => {
		if (env_check) mixpanel.identify(id);
	},
	alias: (id: any) => {
		if (env_check) mixpanel.alias(id);
	},
	track: (name: any, props: any) => {
		if (env_check) mixpanel.track(name, flattenObject(props));
	},
	people: {
		set: (props: any) => {
			if (env_check) mixpanel.people.set(props);
		},
	},
};

export let Mixpanel = actions;
