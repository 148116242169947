import './App.css';
import LogInPage from './pages/LogInPage/LogInPage';
import Dashboard from './pages/Dashboard/Dashboard';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import LoadAndRender from './components/LoadAndRender/LoadAndRender';
import ScrollToTop from './ScrollToTop';
import 'react-toastify/dist/ReactToastify.css';

import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_BACKEND_URL + '/graphql',
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('token');

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
});
const get_user = async (token) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);

	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};
	const { user, school } = await (
		await fetch(process.env.REACT_APP_BACKEND_URL + '/school-auth/get_user', requestOptions)
	).json();
	return { ...user, school };
};

function App() {

	const [user, set_user] = useState(null);
	return (
		<div className='app'>
			<ApolloProvider client={client}>
				<LoadAndRender
					loader
					promise={async () => {
						// this stores token in localstorage if it is in the url for the case when user is redirected from Admin portal
						const urlSearchParams = new URLSearchParams(window.location.search);
						const params = Object.fromEntries(urlSearchParams.entries());
						let param_token = params.token;
						if (param_token) localStorage.setItem('token', param_token);
						const token1 = localStorage.getItem('token');
						if (token1) {
							const user = await get_user(token1);
							const _id = user?._id;
							if (_id) set_user(user);
						}
					}}
				>
					<ScrollToTop />
					<Routes>
						<Route path='/login' element={<LogInPage user={user} set_user={set_user} />} />
						<Route path='*' element={<Dashboard user={user} set_user={set_user} />} />
					</Routes>
					<ToastContainer
						position='top-center'
						autoClose={2000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme='light'
					/>
				</LoadAndRender>
			</ApolloProvider>
		</div>
	);
}

export default App;
