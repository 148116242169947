import React, { useState, createContext } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import StudentDetails from './pages/studentDetails/StudentDetails';
import StudentList from './pages/studentsList/StudentList';
import LoadAndRender from '../../../../components/LoadAndRender/LoadAndRender';

import Modal from '../../../../components/Modal/Modal';
import AddNewStudent1 from './components/AddNewStudent1';
import BackButton from '../../../../components/BackButton/BackButton';
import Loading from '../../Loading';
import StudentProfileSmallLoader from '../SmallSkeleton/StudentProfileSmallLoader';
import { toast } from 'react-toastify';
// import AddNewStudent from './components/AddNewStudent';

export const studentContext = createContext<any>(null);

export const get_students = async () => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/students', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const createStudent = async (data: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify(data);

	var requestOptions: RequestInit = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	let res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/create-student', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		toast.error(message.message);
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

// const AddNewStudent = ({ setAddStudent, setStudents }: any) => {
// 	const [studentData, setStudentData]: [any, any] = useState({});
// 	console.log(studentData);
// 	async function handleAddAdmin() {
// 		var res = await createStudent(studentData);
// 		if (res instanceof Error) {
// 			console.log('something went wrong!');
// 		} else {
// 			setAddStudent((prev: any) => !prev);
// 			studentData._id = res._id;
// 			setStudents((prev: any) => [...prev, studentData]);
// 		}
// 	}
// 	return (
// 		// <div className=' z-[12] absolute  transition duration-300 ease-out top-[50%] left-[50%]  translate-x-[-50%] translate-y-[-50%] w-1/2 bg-[#F8FAFB] rounded-2xl'>
// 		<>
// 			<div className='py-2 px-2  text-center '>
// 				<div className='py-4 text-md font-semibold'>Add New Student</div>
// 				<div onClick={() => setAddStudent((prev: any) => !prev)} className='absolute top-0 right-0 p-7 cursor-pointer'>
// 					<img src={editCross} alt='' />
// 				</div>
// 			</div>
// 			<hr className='px-12' />
// 			<AddEditStudentModal setStudentData={setStudentData} student={[]} />
// 			<div className='mt-2 mb-10 text-center'>
// 				<button onClick={handleAddAdmin} className='py-2 px-16 rounded-lg bg-[#6F6AF8] text-white'>
// 					Add
// 				</button>
// 			</div>
// 		</>

// 		// </div>
// 	);
// };

const StudentProfile = () => {
	const [students, setStudents]: [any, any] = useState();
	const [isAddStudent, setAddStudent] = useState(false);
	const [studentDetails, setStudentDetails]: [any, any] = useState({});
	const [isEditStudent, setEditStudent] = useState(false);
	return (
		<studentContext.Provider
			value={{ students, setStudents, isEditStudent, setEditStudent, setStudentDetails, setAddStudent }}
		>
			<Loading component={<StudentProfileSmallLoader />}>
				<LoadAndRender
					reload_upon_change={[isAddStudent]}
					promise={async () => {
						const res = await get_students();
						setStudents(res);
					}}
					className='w-full'
				>
					<div className='w-full'>

						<div className='max-w-xl w-full'>
							<Modal title='Add New Student' className='max-w-3xl w-full' open={isAddStudent} setOpen={setAddStudent}>
								<AddNewStudent1 createstudent={createStudent} />
							</Modal>
						</div>

						<Routes>
							<Route path='/' element={<StudentList />} />
							<Route path='/:id' element={<StudentDetails />} />
						</Routes>
					</div>
				</LoadAndRender>
			</Loading>
		</studentContext.Provider>
	);
};

export default StudentProfile;
