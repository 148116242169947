import React, { useContext } from 'react';
import { useState } from 'react';
import Select from '../../../../../../components/Select/Select';
import { dashboardContext } from '../../../../Dashboard';

import Input from '../../../../../../components/Input/Input';
import Form from '../../../../../../components/Form/Form';

import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';
export function formatDate(date: Date) {
	var d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
}

const AddEditStudentModal = ({ student, id, get_student, setStudent, setEditStudent, update_student }: any) => {
	const edit = student ? true : false;
	const { classes, sections } = useContext(dashboardContext);
	const [className, setClass]: [any, any] = useState({ name: student.class });
	const [section, setSection]: [any, any] = useState({
		name: student.section,
	});

	const [gender, setGender]: [any, any] = useState({
		name: student
			? student.gender === 'male'
				? 'Male'
				: '' || student.gender === 'female'
				? 'Female'
				: '' || student.gender === 'other'
				? 'Other'
				: ''
			: '',
	});
	const [category, setCategory]: [any, any] = useState({
		name: student ? (student.category === 'general' ? 'General' : student.category.toUpperCase()) : '',
	});

	const [sibling, setSibling]: [any, any] = useState({ name: student.has_sibling ? 'Yes' : 'No' });

	return (
		classes &&
		sections && (
			<>
				<Form
					className='grid grid-cols-2 gap-5 '
					onSubmit={async (data: any) => {
						const studentData = {
							name: data['Name'] || student?.name,
							phone_number: data['Phone No'] || student?.phone_number,
							class: className.name || student?.class,
							section: section.name || student?.section,
							category: category.name.toLowerCase() || student?.category,
							dob: data['Date of Birth'] || formatDate(new Date(Number(student?.dob))),
							gender: gender.name.toLowerCase() || student?.gender,
							has_sibling: sibling ? 'true' : 'false' || student?.has_sibling,
						};

						await update_student(studentData, id);
						setEditStudent((prev: any) => !prev);
						setStudent(await get_student(id));
					}}
				>
					<Input
						type='text'
						name='Name'
						placeholder='Enter Student Name'
						value={student?.name}
						add_error={() => {}}
						required
					/>
					<Input
						type='text'
						name='School generated Id'
						placeholder='Enter School generated Id'
						value={student?.school_generated_id}
						disabled={edit}
						add_error={() => {}}
						required
					/>
					<Select
						selected={gender}
						setSelected={setGender}
						label='Gender'
						options={['male', 'female', 'other'].map((c) => {
							if (c === 'male') {
								return { name: 'Male' };
							}
							if (c === 'female') {
								return { name: 'Female' };
							}
							if (c === 'other') {
								return { name: 'Other' };
							}
							return { name: c };
						})}
					/>
					<Select
						selected={className}
						setSelected={(c: any) => {
							setClass(c);
							setSection(
								sections
									.filter((section: any) => section.class === c.name)
									.map((s: any) => {
										return { ...section, name: s.section };
									})[0],
							);
						}}
						label='Class'
						options={classes.map((c: any) => {
							return { name: c };
						})}
					/>
					{section && (
						<Select
							selected={section}
							setSelected={setSection}
							label='Section'
							options={sections
								.filter((section: any) => section.class === className.name)
								.map((c: any) => {
									return { ...section, name: c.section };
								})}
						/>
					)}
					<Select
						selected={category}
						setSelected={setCategory}
						label='Category'
						options={['General', 'sc', 'st', 'obc'].map((c) => {
							return { name: c === 'General' ? c : c.toUpperCase() };
						})}
					/>
					<Select
						selected={sibling}
						setSelected={setSibling}
						label='Sibling'
						options={['Yes', 'No'].map((c) => {
							return { name: c.toUpperCase() };
						})}
					/>
					<Input
						name='Phone No'
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
						min={0}
						maxLength={10}
						value={student?.phone_number}
						add_error={() => {}}
						type='number'
						placeholder='Enter Phone no'
						required
					/>

					<Input
						type='date'
						name='Date of Birth'
						value={formatDate(student?.dob)}
						min='1990-01-01'
						max={`${new Date().getFullYear()}-${
							new Date().getMonth() > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)
						}-${new Date().getDate() > 9 ? new Date().getDate() + 1 : '0' + new Date().getDate()}`}
						required
					/>
					<div className='mt-2 col-span-2  mb-10 text-center'>
						<button className='py-2 px-16 max-w-[15rem] w-full rounded-lg bg-[#6F6AF8] text-white'>Update</button>
					</div>
				</Form>
			</>
		)
	);
};

export default AddEditStudentModal;
