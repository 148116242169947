import React, { useContext, useEffect, useState } from 'react';
import rightArrow from '../../../assets/rightarrowschool.svg';
import { Link } from 'react-router-dom';
import { dashboardContext } from '../../../Dashboard';

const SchoolList = ({}: any) => {
	const { school } = useContext(dashboardContext);
	const schools = school.school;

	return (
		<>
			<div className='text-2xl  text-violet-600'>School Profile</div>
			<div className='my-3 h-[1px] w-full bg-[#6F6AF8]'></div>
			<div className='bg-[#F8FAFB] font-semibold'>
				<div className='max-w-10xl'>
					<Link to='/school_profile/details'>
						<div className='p-6 rounded-lg  shadow   grid  grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-4 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
							<div className='col-span-1  w-full object-contain lg:col-span-1 border-r-2 border-gray-400'>
								<div className='text-gray-400 font-normal'>School Name</div>
								<div className='mt-4 text-md text-violet-600'>{schools.name}</div>
							</div>
							<div className='col-span-1  w-full object-contain lg:col-span-1 border-r-2 border-gray-400'>
								<div className='text-gray-400 font-normal'>Type of School</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.school_type}</div>
							</div>
							{/* <div className='col-span-2  w-full object-contain lg:col-span-1 border-r-2 border-gray-400'>
								<div className='text-gray-400 font-normal'>School Code</div>
								<div className='mt-4 text-md text-[#1E1B59]'>'12566'</div>
							</div> */}
							<div className='col-span-1  w-full object-contain lg:col-span-1 border-r-2 border-gray-400'>
								<div className='text-gray-400 font-normal'>City</div>
								<div className='mt-4 text-md text-[#1E1B59]'>{schools.address && schools.address.city}</div>
							</div>
							<div className='col-span-1  w-full object-contain lg:col-span-1 '>
								<div className='flex justify-between'>
									<div className='text-gray-400 font-normal'>State</div>
									<Link to='/school_profile/details'>
										<img src={rightArrow} alt='' />
									</Link>
								</div>

								<div className='mt-4 text-md text-[#1E1B59]'>{schools.address && schools.address.state}</div>
							</div>
						</div>
					</Link>
				</div>
			</div>
		</>
	);
};

export default SchoolList;
