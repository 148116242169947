import React from 'react';
import backIcon from '../../../../assets/backicon.svg';
import Table from '../../../../../../components/Table/Table';

import BackButton from '../../../../../../components/BackButton/BackButton';
const Installment = ({ data }: any) => {
	return (
		<div className='bg-violet-100 odd:bg-violet-50 even:bg-gray-50 rounded-2xl'>
			<div className='py-4 px-10 my-2 grid grid-cols-5 gap-4 items-center  rounded-2xl text-center '>
				<div>{data.due_date}</div>
				<div className='text-green-400'>{`₹${data.paid.toLocaleString('hi')}`}</div>
				<div className='text-red-400'>{`₹${data.due.toLocaleString('hi')}`}</div>
				<div>{`₹${data.unpaid.toLocaleString('hi')}`}</div>
				<div>{`₹${data.total.toLocaleString('hi')}`}</div>
			</div>
		</div>
	);
};

const InstallmentWiseReport = ({ reports }: any) => {
	return (
		<div className='text-[] mr-5 mb-5 w-full'>
			<div className='mt-8 flex items-center py-2'>
				{/* <Link to={'..'}>
						<div className='pr-4'>
							<img src={backIcon} alt='' />
						</div>

					</Link> */}
				{/* <div className=' text-2xl font-semibold text-violet-600'>Installment Wise Report</div> */}
				<BackButton label='Installment Wise Report' />
			</div>
			<p className='text-[#fc4343] text-xs ml-5'>
				All Amounts shown here are calculated without considering fines. Please refer to the Fine Wise Report to view
				the fines applied.*
			</p>
			{/* <div className='m-5'>
					<div className='py-4 px-10 text-l font-semibold grid grid-cols-5 gap-4  rounded-2xl text-center   bg-violet-100 text-violet-900'>
						<div>Due Date</div>
						<div>Paid</div>
						<div>Overdue</div>
						<div>Unpaid</div>
						<div>Total</div>
					</div>
					{reports && (
						<>
							{reports.map((data: any) => {
								return <Installment key={data.due_date} data={data} />;
							})}
						</>
					)}
				</div> */}
			{reports ? (
				<Table
					heading='Installment Wise Report'
					data={[
						['Due Date', 'Paid', 'Unpaid', 'Total'],
						...reports.map((data: any) => [
							new Date(data?.due_date).toDateString(),
							<h2 className=' text-green-400'>₹{data?.total_amount_paid?.toLocaleString('hi')}</h2>,

							<h2 className=' text-red-400'>
								₹{(data?.total_amount - data?.total_amount_paid)?.toLocaleString('hi')}
							</h2>,
							<h2>₹{data?.total_amount?.toLocaleString('hi')}</h2>,
						]),
					]}
				/>
			) : (
				''
			)}
		</div>
	);
};

export default InstallmentWiseReport;
