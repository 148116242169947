import React from 'react';

function FeeConfigSmallLoader() {
	return (
		<div className='flex w-full'>
			<div className='flex flex-col pr-8 w-full'>
				<div className='flex pl-8 w-full flex-1'>
					<div className='flex w-full flex-col'>
						<div className='flex justify-between items-center'>
							<div className='bg-gray-300 animate-pulse max-w-[12rem] mt-3 w-full h-10 rounded-lg'></div>
							<div className='bg-gray-300 animate-pulse max-w-[15rem] mt-3 w-full h-10 rounded-lg'></div>
						</div>
						<div className='grid grid-cols-2 gap-5 mt-5'>
							<div className='bg-gray-300 animate-pulse w-full h-60 rounded-lg'></div>
							<div className='bg-gray-300 animate-pulse w-full h-60 rounded-lg'></div>
							<div className='bg-gray-300 animate-pulse w-full h-60 rounded-lg'></div>
							<div className='bg-gray-300 animate-pulse w-full h-60 rounded-lg'></div>
							<div className='bg-gray-300 animate-pulse w-full h-60 rounded-lg'></div>
							<div className='bg-gray-300 animate-pulse w-full h-60 rounded-lg'></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeeConfigSmallLoader;
