import { gql } from '@apollo/client';

export const GET_FINE_DATA = gql`
{
    school{
      instllment_wise_fine_report{
        due_date
        total_amount
        total_students
      }
    }
  }
`;

