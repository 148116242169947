import React from 'react';

function FeeReportsSmallLoader() {
	return (
		<div className='flex flex-col w-full pr-4'>
			<div className='border-b-2 w-full pb-3'>
				<div className='w-40 h-10 bg-gray-300 animate-pulse rounded-lg'></div>
			</div>
			<div className='w-full mt-3'>
				<div className='w-60 h-10 bg-gray-300 animate-pulse rounded-lg'></div>
				<div className='grid gap-8 grid-cols-2 mt-4'>
					<div className='bg-gray-300 rounded-lg animate-pulse h-80'></div>
					<div className='bg-gray-300 rounded-lg animate-pulse h-80'></div>
				</div>
			</div>
			<div className='w-full mt-4 h-96 bg-gray-300 rounded-lg animate-pulse'></div>
		</div>
	);
}

export default FeeReportsSmallLoader;
