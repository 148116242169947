import React from 'react';
import backIcon from '../../../../assets/backicon.svg';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import { Features } from '@headlessui/react/dist/utils/render';
import { Link } from 'react-router-dom';
const TimeReport = ({ data }: any) => {
	return (
		<div className='bg-violet-100 odd:bg-violet-50 even:bg-gray-50 rounded-2xl'>
			<div className='py-4 px-10 my-2 grid grid-cols-4 gap-4 items-center  rounded-2xl text-center '>
				<div>{data.date}</div>
				<div>{data.feeType[0]}</div>
				<div>{`₹${data.amountCollected}`}</div>
				<div>{`₹${data.pending}`}</div>
			</div>
		</div>
	);
};

const TimeWiseReport = () => {
	const data = [
		{
			date: '03 June 2023',
			feeType: ['AA Type', 'BB Type'],
			amountCollected: '2,00,000',
			pending: '17000',
		},
	];
	return (
		<div className='text-[] w-full'>
			<div className='mt-8 flex items-center py-2'>
				<Link to={'..'}>
					<div className='pr-4'>
						<img src={backIcon} alt='' />
					</div>
				</Link>
				<div className=' text-2xl font-semibold text-violet-600'>Time Wise Report</div>
			</div>
			<div className='mt-2'>
				<div className='py-4 px-10 text-l font-semibold grid grid-cols-4 gap-4  rounded-2xl text-center   bg-violet-100 text-violet-900'>
					<div>Date</div>
					<div>Fee Type</div>
					<div>Amount Collected</div>
					<div>Pending cheque & DD</div>
				</div>
				{Array(8)
					.fill(0)
					.map(() => {
						return <TimeReport data={data[0]} />;
					})}
			</div>
		</div>
	);
};

export default TimeWiseReport;
