import React, { useContext } from 'react';
import Input from '../../../../../../components/Input/Input';
import Form from '../../../../../../components/Form/Form';

import { dashboardContext } from '../../../../Dashboard';
import Modal from '../../../../../../components/Modal/Modal';
import Table from '../../../../../../components/Table/Table';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Loading from '../../../../Loading';
import FeeConfigFineSmallLoader from '../../../SmallSkeleton/FeeConfigFineSmallLoader';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';

const update_fine = async (id: String, fine: number, grace_period: number, fine_after_grace_period: number) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({
		section_id: id,
		fine_per_day: fine,
		fine_grace_period: grace_period,
		fine_after_grace_period: fine_after_grace_period,
	});

	var requestOptions: RequestInit = {
		method: 'PATCH',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/section-fine', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};
const set_fee_for_all_classes = async (fine: number, grace_period: number) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({
		fine_per_day: fine,
		fine_grace_period: grace_period,
	});

	var requestOptions: RequestInit = {
		method: 'PATCH',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/fine', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const FineTab = () => {
	const { sections, set_sections } = useContext(dashboardContext);
	const [sectionId, setSectionId] = React.useState('');
	const [showModal, setShowModal] = React.useState(false);
	const [showModal1, setShowModal1] = React.useState(false);
	console.log(sections);

	return (
		<Loading component={<FeeConfigFineSmallLoader />}>
			<LoadAndRender promise={async () => {}} height='full'>
				<div className='fine-tab flex-col w-full m-5 flex'>
					<div className='w-full mt-5'>
						<Modal open={showModal} setOpen={setShowModal} title='Classes'>
							{
								<div className='mx-auto text-center'>
									<Form
										onSubmit={async (data: any) => {
											set_sections(
												sections.map((s: any) =>
													s._id === sectionId
														? {
																...s,
																fine_per_day: Number(data['Fine (per day)']),
																fine_grace_period: Number(data['Grace Period (in Days)']),
																fine_after_grace_period: Number(data['Fine After Grace Period']),
														  }
														: s,
												),
											);
											await update_fine(
												sectionId,
												Number(data['Fine (per day)']),
												Number(data['Grace Period (in Days)']),
												Number(data['Fine After Grace Period']),
											);
											setShowModal(false);
										}}
									>
										<Input
											label=''
											name='Fine (per day)'
											type='number'
											onKeyDown={preventNegativeValues}
											onPaste={preventPasteNegative}
											min={0}
											prefix='₹'
											required
										/>
										<Input
											name='Grace Period (in Days)'
											type='number'
											onKeyDown={preventNegativeValues}
											onPaste={preventPasteNegative}
											min={0}
											required
										/>
										<Input
											name='Fine After Grace Period'
											type='number'
											onKeyDown={preventNegativeValues}
											onPaste={preventPasteNegative}
											min={0}
											required
										/>
										<button className='bg-[#6F6AF8] ml-auto mt-5 text-white px-10 py-2 rounded-lg hover:bg-[#6F6AF8]'>
											Update
										</button>
									</Form>
								</div>
							}
						</Modal>
						<Table
							heading={'Section Wise Fine'}
							data={[
								['Classes', 'Section', 'Fine Grace Period', 'Fine After Grace Period', 'Fine Per Day', 'Action'],
								...sections.map((r: any) => [
									<div>{r.class}</div>,
									<div>{r.section}</div>,
									<div>{r.fine_grace_period} Days</div>,
									<div>{r.fine_after_grace_period}</div>,
									<h2 className={r.fine_per_day > 0 ? 'text-[#2EB418]' : ' '}>
										{'₹ ' + r.fine_per_day.toLocaleString('hi')}
									</h2>,

									<i
										className='cursor-pointer fa-solid fa-pen'
										onClick={() => {
											setShowModal(true);
											setSectionId(r._id);
										}}
									></i>,
								]),
							]}
							footer={<div></div>}
						/>
					</div>
				</div>
			</LoadAndRender>
		</Loading>
	);
};

export default FineTab;
