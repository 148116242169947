import React, { createContext, useContext } from 'react';

import Table from '../../../../../../components/Table/Table';
import Switch from '../../../../../../components/Switch/Switch';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Form from '../../../../../../components/Form/Form';
import Input from '../../../../../../components/Input/Input';
import CheckboxInput from '../../../../../../components/CheckboxInput/CheckboxInput';
import Modal from '../../../../../../components/Modal/Modal';
import Select from '../../../../../../components/Select/Select';
import RadioInput from '../../../../../../components/RadioInput/RadioInput';
import { dashboardContext } from '../../../../Dashboard';

import GroupDiscountCard from './components/GroupDiscountCard';
import CreateGroupDiscount, { DiscountType } from './components/CreateGroupDiscount';
import EditStudentDiscount from './components/EditStudentDiscount';
import { Link } from 'react-router-dom';
import Loading from '../../../../Loading';
import FeeConfigDiscountsSmallLoader from '../../../SmallSkeleton/FeeConfigDiscountsSmallLoader';

import useOTP from '../../../../../../hooks/useOTP';
import OtpModal from '../../../../../../components/OTPModal/OtpModal';

import { Access } from '../../../PortalAccess/AccessEnum';
import SiblingDiscount from './components/SiblingDiscount';
export enum DiscountApplicableOn {
	INDIVIDUAL = 'INDIVIDUAL',
	GROUP = 'GROUP',
	SIBLING = 'SIBLING',
}

export const deletDiscount = async (id: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'DELETE',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/fee-config/discount/${id}`, requestOptions);
	if (res.ok) {
		return res;
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

export const dicountContext = createContext<any>(null);
const DiscountsTab = () => {
	const [selected, set_selected] = React.useState(0);
	const [get_discounts, set_get_discounts] = React.useState([]);
	const [show_modal, set_show_modal] = React.useState(false);
	const [show_sibling_modal, set_show_sibling_modal] = React.useState(false);
	const [delete_discount_modal, set_delete_discount_modal] = React.useState(false);
	const [isEdit, setIsEdit] = React.useState(false);
	const [single_discount, set_single_discount] = React.useState<any>([]);
	const [id, setId] = React.useState(null);
	const { classes } = React.useContext(dashboardContext);
	const [_gender, set_gender] = React.useState<any>('');
	const [_category, set_category] = React.useState<any>('');
	const [_class, set_class] = React.useState<any>('');
	const [fee_heads, set_fee_heads] = React.useState<any>([]);
	const [get_fee_heads, set_get_fee_heads] = React.useState<any>([]);
	const [fee_head, set_fee_head] = React.useState<any>({});
	const [discount_type, set_discount_type] = React.useState<any>('RECURRING');

	//const { otp, handleGenerateOTP, modalOpen, setModalOpen } = useOTP();

	const { user } = useContext(dashboardContext);

	const getDiscounts = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/fee-config/discounts`, requestOptions);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};
	const getFeeHeads = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/school/fee_heads`, requestOptions);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	const updateDiscount = async (data: any) => {
		var myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		// var raw = JSON.stringify({
		// 	applicable_on: 'GROUP',
		// 	name: data.name,
		// 	type: data.type,
		// 	value: data.value,
		// 	group_category: data.group_category,
		// 	group_gender: data.group_gender,
		// 	group_class: data.group_class,
		// });
		// console.log(raw, id);
		var raw = JSON.stringify(data);

		var requestOptions: RequestInit = {
			method: 'PATCH',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/fee-config/discount/${id}`, requestOptions);
		if (res.ok) {
			return res;
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};
	const createDiscount = async (data: any) => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');

		var raw = JSON.stringify({
			applicable_on: 'GROUP',
			fee_head: data.fee_head,
			name: data.name,
			type: data.type,
			discount_type: data.discount_type,
			value: data.value,
			group_category: data.group_category,
			group_gender: data.group_gender,
			group_class: data.group_class,
		});
		var requestOptions: RequestInit = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/fee-config/discount', requestOptions);
		if (res.ok) {
			return res;
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	const getFeeHeadForGroup = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				`/api/fee-config/fee-head-groupa?gender=${_gender}&category=${_category}&class=${_class}`,
			requestOptions,
		);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	React.useEffect(() => {
		(async () => {
			if (_category && _class && _gender) {
				set_fee_heads(await getFeeHeadForGroup());
			}
		})();
	}, [_category, _gender, _class]);
	// console.log();

	return (
		<Loading component={<FeeConfigDiscountsSmallLoader />}>
			<LoadAndRender
				promise={async () => {
					set_get_discounts(await getDiscounts());
					const res = await getFeeHeads();
					set_get_fee_heads(res);
				}}
			>
				<div className='m-5 w-full '>
					<Modal
						className='max-w-4xl w-full'
						title={isEdit ? 'Edit Discount' : 'Create Group Discount'}
						open={show_modal}
						setOpen={set_show_modal}
					>
						<>
							<hr />
							{isEdit ? (
								<EditStudentDiscount
									fee_head={fee_head}
									fee_heads={fee_heads}
									set_fee_head={set_fee_head}
									classes={classes}
									discount_type={discount_type}
									single_discount={single_discount}
									set_discount_type={set_discount_type}
									updateDiscount={updateDiscount}
									getDiscounts={getDiscounts}
									set_get_discounts={set_get_discounts}
									set_show_modal={set_show_modal}
								/>
							) : (
								<CreateGroupDiscount
									classes={classes}
									set_discount_type={set_discount_type}
									discount_type={discount_type}
									createDiscount={createDiscount}
									set_get_discounts={set_get_discounts}
									getDiscounts={getDiscounts}
									set_show_modal={set_show_modal}
									show_modal={show_modal}
								/>
							)}
						</>
					</Modal>
					<Modal
						className='max-w-4xl w-full'
						title={'Create Sibiling Discount'}
						open={show_sibling_modal}
						setOpen={set_show_sibling_modal}
					>
						<>
							<hr />
							<SiblingDiscount
								set_show_sibling_modal={set_show_sibling_modal}
								classes={classes}
								set_get_discounts={set_get_discounts}
								getDiscounts={getDiscounts}
							/>
						</>
					</Modal>
					<Modal title='Delete' open={delete_discount_modal} setOpen={set_delete_discount_modal}>
						<div className='w-11/12 m-auto text-center'>
							<div className='mt-10'>Are you sure you want to delete this discount?</div>
							<div className='mt-10 mb-10 grid grid-cols-2 gap-4'>
								<button
									onClick={() => set_delete_discount_modal(false)}
									className='py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none'
								>
									Cancel
								</button>
								<button
									onClick={async () => {
										deletDiscount(id);
										set_get_discounts(await getDiscounts());
										set_delete_discount_modal(false);
									}}
									className='py-2 px-4 rounded-md bg-red-400 text-white outline-none'
								>
									Delete
								</button>
							</div>
						</div>
					</Modal>
					{/* {modalOpen && <OtpModal OTP={otp} />} */}
					{/* <Modal open={modalOpen} setOpen={setModalOpen} className='max-w-sm w-full'>
						<OtpModal OTP={otp} />
					</Modal> */}
					<div className='flex justify-between items-center h-20'>
						<Switch
							options={['Group Discount', 'Student Discount']}
							selected={selected}
							set_selected={set_selected}
						></Switch>
						{selected === 0 && (
							<div className='flex flex-col gap-y-2'>
								<button
									onClick={() => {
										set_show_modal(true);
										setIsEdit(false);
									}}
									className=' bg-[#6F6AF8] text-white px-10 py-2  rounded-md'
								>
									+ Create Discount
								</button>
								{/* <span
									onClick={() => {
										set_show_sibling_modal(true);
										// setIsEdit(false);
									}}
									className=' hover:underline hover:underline-offset-1 cursor-pointer text-right text-sm rounded-md text-indigo-500'
								>
									+ Create Sibling Discount
								</span> */}
							</div>
						)}
					</div>
					{selected === 0 ? (
						<div className='grid lg:grid-cols-2 grid-cols-1 gap-5 my-5'>
							{get_discounts
								.filter((student: any) => student['applicable_on'] === 'GROUP')
								.map((discount: any) => (
									<GroupDiscountCard
										key={discount._id}
										getDiscounts={getDiscounts}
										set_get_discounts={set_get_discounts}
										discount={discount}
										get_fee_heads={get_fee_heads}
									/>
								))}
						</div>
					) : (
						<div>
							<Table
								heading={'Discounts'}
								data={[
									['Student Name', 'Discount Name', 'Frequency', 'Value', 'Action'],
									...get_discounts
										.filter((student) => student['applicable_on'] === 'INDIVIDUAL')
										.map((discount: any) => [
											<div className='font-medium text-violet-500'>
												<Link to={`/student_profile/${discount.student?._id}`}>{discount.student?.name}</Link>
											</div>,
											<div>{discount?.name}</div>,

											<div>
												{discount?.discount_type === DiscountType.ABSOLUTE_ONE_TIME ? 'One Time' : 'Recurring'}
											</div>,
											<div>
												{discount?.discount_type === DiscountType.ABSOLUTE_ONE_TIME
													? `₹ ${discount?.value.toLocaleString('hi')}`
													: `${discount?.value} % of ${
															discount?.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD
																? get_fee_heads.filter((f: any) => f._id === discount?.fee_head)[0]?.name
																: discount?.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT
																? 'Installment'
																: null
													  }`}
											</div>,

											<div className='space-x-10'>
												<i
													className='fa-solid fa-pen cursor-pointer'
													onClick={async () => {
														set_show_modal(true);
														setIsEdit(true);
														setId(discount?._id);
														set_single_discount(discount);
													}}
												></i>
												{(user.access === Access.SUPER || user.access === Access.ALL_ACCESS) && (
													<i
														onClick={async () => {
															setId(discount?._id);
															// await deletDiscount(discount._id);
															// set_get_discounts(await getDiscounts());
															set_delete_discount_modal(true);
														}}
														className='fa-solid fa-trash cursor-pointer'
													></i>
												)}
											</div>,
										]),
								]}
								footer={<div></div>}
							/>
						</div>
					)}
				</div>
			</LoadAndRender>
		</Loading>
	);
};

export default DiscountsTab;
