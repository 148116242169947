import React from 'react';
import Form from '../../../../../../../components/Form/Form';
import CheckboxInput from '../../../../../../../components/CheckboxInput/CheckboxInput';
import Input from '../../../../../../../components/Input/Input';
import { preventNegativeValues } from '../../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../../helper/preventPasteNegative';
import { boolean_map_to_array } from '../../FeeStructureTab/FeeStructureTab';
import RadioInput from '../../../../../../../components/RadioInput/RadioInput';
import { CREATE_SIBLING_DISCOUNT } from '../Queries';
import { useMutation } from '@apollo/client';
import { DiscountType } from './CreateGroupDiscount';

function SiblingDiscount({ classes, getDiscounts, set_get_discounts, set_show_sibling_modal }: any) {
	const [_classes, setClasses] = React.useState<any>([]);

	const [discountType, setDiscountType] = React.useState<any>('');
	const [discount_type, set_discount_type] = React.useState<any>('');
	const [fee_heads, set_fee_heads] = React.useState<any>([]);
	const [fee_head, set_fee_head] = React.useState<any>({});
	const [isFeehead, setIsFeeHead] = React.useState<any>(false);
	const [createSiblingDiscount, { data: result, loading, error }] = useMutation(CREATE_SIBLING_DISCOUNT);
	const getFeeHeadForSibling = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(
			process.env.REACT_APP_BACKEND_URL + `/api/fee-config/fee-head-group?class=${_classes}`,
			requestOptions,
		);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	React.useEffect(() => {
		(async () => {
			if (isFeehead === true) {
				if (_classes.length) {
					set_fee_heads(await getFeeHeadForSibling());
				}
			}
		})();
	}, [_classes]);
	// console.log(fee_head);

	return (
		<div>
			<div className='w-full flex justify-center items-center'>
				<RadioInput
					onChange={(n: any, v: any) => {
						set_discount_type(n);
						setDiscountType(() => {
							if (v === 'Absolute One Time') return 'absolute-one-time';
							if (v === 'Recurring Percentage') return 'recurring-percentage';
						});

						setClasses([]);
					}}
					color='text-violet-500'
					add_error={() => {}}
					options={['Absolute One Time', 'Recurring Percentage']}
					required
				/>
			</div>
			{discountType === 'absolute-one-time' && (
				<Form
					onSubmit={async (data: any) => {
						try {
							await createSiblingDiscount({
								variables: {
									discount_name: data['Discount Name'],
									value: Number(data['Value']),
									class: _classes,
									type: DiscountType.ABSOLUTE_ONE_TIME,
								},
							});
							set_get_discounts(await getDiscounts());
							set_show_sibling_modal(false);
						} catch (err) {
							console.log('errr0r', err);
						}
					}}
					className='grid grid-cols-2 gap-5'
				>
					<Input add_error={() => {}} name='Discount Name' type='text' placeholder='Enter Name' required />
					<Input
						add_error={() => {}}
						name='Value'
						type='number'
						placeholder='Enter Value'
						prefix={'₹'}
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
						min={0}
						required
					/>

					<CheckboxInput
						onChange={(e: any) => {
							setClasses(boolean_map_to_array(e));
						}}
						name='Class'
						options={classes}
						required
					/>
					<div className='col-span-2 flex justify-center items-center'>
						<button
							disabled={!_classes.length}
							className={
								' ml-auto mx-auto max-w-[10rem] w-full inline-flex disabled:bg-[#6F6AF8] disabled:bg-opacity-70 bg-[#6F6AF8] justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]  sm:w-auto '
							}
						>
							Create Discount
						</button>
					</div>
				</Form>
			)}
			{discountType === 'recurring-percentage' && (
				<Form
					className='grid grid-cols-2 gap-5'
					onSubmit={async (data: any) => {
						try {
							if (isFeehead === true) {
								await createSiblingDiscount({
									variables: {
										discount_name: data['Discount Name'],
										value: Number(data['Value']),
										class: _classes,
										fee_head_id: fee_head[0]?._id,
										type: DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD,
									},
								});
							} else {
								await createSiblingDiscount({
									variables: {
										discount_name: data['Discount Name'],
										value: Number(data['Value']),
										class: _classes,
										type: DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT,
									},
								});
							}
							set_get_discounts(await getDiscounts());
							set_show_sibling_modal(false);
						} catch (err) {
							console.log('errror', err);
						}
					}}
				>
					<Input add_error={() => {}} name='Discount Name' type='text' placeholder='Enter Name' required />

					<Input
						add_error={() => {}}
						name='Value'
						type='number'
						placeholder='Enter Value'
						prefix={'%'}
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
						maxAmount={100}
						min={0}
						required
					/>
					<RadioInput
						name='Discount'
						onChange={(n: any) => {
							setIsFeeHead(() => {
								if (n === 'Feehead') {
									return true;
								}
								if (n === 'Installment') {
									return false;
								}
							});
						}}
						add_error={() => {}}
						options={['Feehead', 'Installment']}
						required
					/>

					<CheckboxInput
						onChange={(e: any) => {
							setClasses(boolean_map_to_array(e));
						}}
						name='Class'
						options={classes}
						required
					/>

					{isFeehead === true ? (
						<>
							{fee_heads?.length > 0 ? (
								<RadioInput
									name='Select Fee Head'
									onChange={(e: any, v: any) => {
										set_fee_head(
											Object.values(fee_heads).filter((fee: any) => {
												return fee.name === v;
											}),
										);
									}}
									add_error={() => {}}
									options={Object.values(fee_heads).map((fee: any) => {
										return fee.name;
									})}
									required
								/>
							) : (
								<></>
							)}
						</>
					) : (
						<></>
					)}
					<div className='col-span-2 flex justify-center items-center'>
						<button
							disabled={isFeehead === true ? !(_classes.length && fee_head?.length) : !_classes.length}
							className={
								' ml-auto mx-auto max-w-[10rem] w-full inline-flex disabled:bg-[#6F6AF8] disabled:bg-opacity-70 bg-[#6F6AF8] justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]  sm:w-auto '
							}
						>
							Create Discount
						</button>
					</div>
				</Form>
			)}
		</div>
	);
}

export default SiblingDiscount;
