import React, { useEffect } from 'react';
import { useState, useContext } from 'react';
import DetailOptions from './DetailOptions';
import BasicDetails from '../../components/BasicDetails';
import { useParams } from 'react-router-dom';
import { studentContext } from '../../StudentProfile';
import AddEditStudentModal from '../studentsList/AddEditStudentModal';
import { get_students } from '../../StudentProfile';
import Modal from '../../../../../../components/Modal/Modal';
import BackButton from '../../../../../../components/BackButton/BackButton';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';
import { GET_INSTALLMENTS } from '../../../FeeCollection/pages/CollectOverview/Queries';
import { GET_STUDENT } from './Queries';
const update_student = async (data: any, id: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify(data);

	var requestOptions: RequestInit = {
		method: 'PATCH',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	let res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/student/${id}`, requestOptions);
	if (res.ok) {
		toast.success('student is update');
		return await res.json();
	} else {
		const message = await res.json();
		toast.error('something goes wrong while updating');
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};
export const get_student = async (id: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,

		redirect: 'follow',
	};

	let res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/student/${id}`, requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			//window.location.reload();
		}
	}
};

const StudentDetails = () => {
	const { setEditStudent } = useContext(studentContext);
	const { isEditStudent } = useContext(studentContext);
	const [selectedComponent, setSelectedComponent] = useState(null);
	const [studentUpdateStatus, setStudentUpateStatus] = useState(false);
	const { id } = useParams();
	const { data: installments_query_response } = useQuery(GET_INSTALLMENTS, {
		variables: {
			id: id,
		},
	});
	const { data, loading } = useQuery(GET_STUDENT, {
		variables: {
			id: id,
		},
	});

	const update_student = async (data: any, id: any) => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');

		var raw = JSON.stringify(data);

		var requestOptions: RequestInit = {
			method: 'PATCH',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		let res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/student/${id}`, requestOptions);
		if (res.ok) {
			toast.success('student is update');
			window.location.reload();
			return await res.json();
		} else {
			const message = await res.json();
			window.location.reload();

			toast.error('something goes wrong while updating');
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	return (
		<LoadAndRender loading={loading}>
			<div className='p-2 w-full'>
				<div className='my-2'>
					<BackButton label='Student Profile' />
				</div>
				<BasicDetails setEditStudent={setEditStudent} student={data?.student} isEdit={true} />
				<div className='w-1/2'>
					<Modal className='max-w-3xl w-full' title='Edit Student' open={isEditStudent} setOpen={setEditStudent}>
						<AddEditStudentModal
							id={id}
							setEditStudent={setEditStudent}
							get_student={get_student}
							update_student={update_student}
							student={data?.student}
						/>
					</Modal>
				</div>

				<div className='flex'>
					<DetailOptions
						studentData={data?.student}
						getStudent={get_student}
						updateStudent={update_student}
						studentId={id}
						setSelectedComponent={setSelectedComponent}
						installments={installments_query_response?.data?.installments}
					/>

					{selectedComponent && selectedComponent}
				</div>
			</div>
		</LoadAndRender>
	);
};

export default StudentDetails;
