import React, { useState } from 'react';
import backIcon from '../../../../assets/backicon.svg';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import { Features } from '@headlessui/react/dist/utils/render';
import Table from '../../../../../../components/Table/Table';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../../components/BackButton/BackButton';
import Select from '../../../../../../components/Select/Select';
import { gql, useQuery } from '@apollo/client';

const PaymentModeWiseReport = () => {
	const { data, loading, error } = useQuery(gql`
		{
			school {
				payment_mode_report {
					cash
					cheque
					dd
					online
				}
			}
		}
	`);
	return (
		<LoadAndRender>
			<div className='text-[] mr-5 mb-5 w-full'>
				<div className='mt-8 flex items-center py-2'>
					<BackButton label='Payment Mode Wise Report' />
				</div>
				<p className='text-[#fc4343] text-xs ml-5'>
					All Amounts shown here are calculated without considering fines. Please refer to the Fine Wise Report to view
					the fines applied.*
				</p>
				<Table
					heading=''
					data={[
						['Payment Mode', 'Amount'],
						['Cash', '₹' + data?.school?.payment_mode_report?.cash.toLocaleString('hi')],
						['Cheque', '₹' + data?.school?.payment_mode_report?.cheque.toLocaleString('hi')],
						['DD', '₹' + data?.school?.payment_mode_report?.dd.toLocaleString('hi')],
						['Online', '₹' + data?.school?.payment_mode_report?.online.toLocaleString('hi')],
					]}
				/>
			</div>
		</LoadAndRender>
	);
};

export default PaymentModeWiseReport;
