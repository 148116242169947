import React, { useEffect, useState } from 'react';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import FeeCollected from '../../components/FeeCollected';
import FeeOverview from '../../components/FeeOverview';
import ViewReports from '../../components/ViewReports';
import Select from '../../../../../../components/Select/Select';

// const get_fee_collected_data = async () => {
// 	var myHeaders = new Headers();
// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

// 	var requestOptions: RequestInit = {
// 		method: 'GET',
// 		headers: myHeaders,
// 		redirect: 'follow',
// 	};

// 	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/fee-reports/month-wise', requestOptions);
// 	if (res.ok) {
// 		return await res.json();
// 	} else {
// 		const message = await res.json();
// 		if (message.statusCode === 401) {
// 			localStorage.removeItem('token');
// 			window.location.reload();
// 		}
// 	}
// };
// const getTotalCount = (res: any, data: any) => {
// 	let total = 0;
// 	for (let i = 0; i < data.length; i++) {
// 		total += res.applicable[data[i]].count;
// 	}
// 	return total;
// };
// const getTotalPendingCount = (res: any, data: any) => {
// 	let total = 0;
// 	for (let i = 0; i < data.length; i++) {
// 		total += res.pending[data[i]].count;
// 	}
// 	return total;
// };
// const getTotalAmount = (res: any, data: any) => {
// 	let amount = 0;
// 	for (let i = 0; i < data.length; i++) {
// 		amount += res.applicable[data[i]].amount;
// 	}
// 	return amount;
// };
// const getTotalPendingAmount = (res: any, data: any) => {
// 	let amount = 0;
// 	for (let i = 0; i < data.length; i++) {
// 		amount += res.pending[data[i]].amount;
// 	}
// 	return amount;
// };
const FeeReportDasboard = ({ overview_data }: any) => {
	const [studentCount, setStudentCount] = useState({ count: 0, amount: 0 });
	const [studentPaidCount, setStudentPaidCount] = useState({ count: 0, amount: 0 });
	const [studentDueCount, setStudentDueCount] = useState({ count: 0, amount: 0 });
	const [discountAmount, SetDiscountAmount] = useState({ count: 0, amount: 0 });
	const Feelabels = ['Applied Fee Amount', 'Discount Fee Amount', 'Paid Fee Amount', 'Due Fee Amount'];
	const Studentlabels = ['Total Students', 'Student Discount Count', 'Students with no dues', 'Students with dues'];
	const [feeData, setFeeData] = useState<any>(
		overview_data?.school?.instllment_wise_fee_report.map((installment: any) => {
			return {
				installmentdate: new Date(installment.due_date).toDateString(),
				total_amount: installment.total_amount,
				total_amount_paid: installment.total_amount_paid,
				total_students: installment.total_students,
				total_students_paid: installment.total_students_paid,
			};
		}),
	);

	const [filter_options, set_filter_options] = useState<any>();
	const [selected, set_selected] = useState<any>();

	return (
		<LoadAndRender
			promise={async () => {
				const arr = {
					'Annual Report': {
						total_amount_paid:
							(overview_data?.school?.instllment_wise_fee_report.length > 0 &&
								overview_data?.school?.instllment_wise_fee_report
									?.map((d: any) => d.total_amount_paid)
									?.reduce((a: any, b: any) => a + b)) ||
							0,
						total_amount:
							(overview_data?.school?.instllment_wise_fee_report.length > 0 &&
								overview_data?.school?.instllment_wise_fee_report
									.map((d: any) => d.total_amount)
									.reduce((a: any, b: any) => a + b)) ||
							0,
						total_students:
							(overview_data?.school?.instllment_wise_fee_report[11]?.total_students &&
								overview_data?.school?.instllment_wise_fee_report[11]?.total_students) ||
							0,
						total_students_paid: overview_data?.school?.instllment_wise_fee_report[11]?.total_students_paid || 0,
					},
					...overview_data?.school?.instllment_wise_fee_report,
				};

				set_filter_options(arr);
			}}
		>
			<div className='w-full p-5'>
				<div className='text-2xl font-semibold text-violet-600'>Fee Reports</div>
				<div className='my-3 h-[1px] w-full bg-[#6F6AF8]'></div>
				<div className='w-72'>
					{filter_options && (
						<Select
							selected={selected}
							setSelected={(e: any) => {
								set_selected(e);

								setStudentCount({
									count: e?.total_students,
									amount: e?.total_amount,
								});
								setStudentPaidCount({ count: e?.total_students_paid, amount: e?.total_amount_paid });
								setStudentDueCount({
									count: e?.total_students - e?.total_students_paid,
									amount: e?.total_amount - e?.total_amount_paid,
								});
							}}
							options={['Annual Report', ...Object.keys(filter_options).filter((f: any) => f !== 'Annual Report')].map(
								(e: any, i: number) => {
									return {
										name:
											e === 'Annual Report' ? (
												e
											) : (
												<h3 className='text-sm'>{`Installment ${i} (${new Date(
													filter_options[e].due_date,
												).toDateString()})`}</h3>
											),
										total_amount: filter_options[e].total_amount,
										total_amount_paid: filter_options[e].total_amount_paid,
										total_students: filter_options[e].total_students,
										total_students_paid: filter_options[e].total_students_paid,
									};
								},
							)}
						/>
					)}
				</div>
				<div className='py-2 grid grid-cols-2 gap-x-4'>
					<div className=''>
						<FeeOverview
							feeLabels={Feelabels}
							studentLabels={Studentlabels}
							studentCount={studentCount}
							studentPaidCount={studentPaidCount}
							studentDueCount={studentDueCount}
							discountAmount={discountAmount}
						/>
					</div>
					<div className=''>{feeData && <FeeCollected feeData={feeData} />}</div>
				</div>
				<ViewReports />
			</div>
		</LoadAndRender>
	);
};

export default FeeReportDasboard;
