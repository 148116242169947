import React, { useContext, useState, useEffect } from 'react';
import { dashboardContext } from '../../../../Dashboard';
import Table from '../../../../../../components/Table/Table';
import './FeeStructureTable.css';
const FeeStructureModal = ({ fee_structure, setPrintContent }: any) => {
	const { classes } = useContext(dashboardContext);

	console.log({ fee_structure });

	return (
		<>
			<div ref={(e) => setPrintContent(e)} className='p-4'>
				{Object.keys(fee_structure).map((due: any, key) => {
					let obj: any = {};
					return (
						<table className='feehead-table'>
							<thead>
								<tr>
									{[
										<th>{new Date(due)?.toDateString()}</th>,
										...classes.map((c: any) => {
											obj[c] = 0;
											return <th className=''>{`class ${c}`}</th>;
										}),
									]}
								</tr>
							</thead>
							<tbody>
								{fee_structure[due].map((fee_head: any) => [
									<tr>
										{[
											<td>{fee_head?.name}</td>,

											...classes.map((c: any) => {
												if (fee_head.clas.includes(c)) {
													obj[c] += fee_head.amount;
													return <td>₹ {fee_head.amount}</td>;
												} else return <td>₹ {0}</td>;
											}),
										]}
									</tr>,
								])}
							</tbody>
							<tfoot>
								<tr>
									<th>Total : </th>
									{Object.keys(obj).map((c) => {
										return <td>₹ {obj[c]}</td>;
									})}
								</tr>
							</tfoot>
						</table>
					);
				})}
			</div>
		</>
	);
};

export default FeeStructureModal;
