import React from 'react';

const DisplayCard = ({ value, label }: { value: string; label: string }) => {
	return (
		<div className='p-4 rounded-2xl h-28 max-w-[50%] w-full shadow flex flex-col justify-between'>
			<h4 className='text-[#959595] font-normal text-base'>{label}</h4>
			<p className='text-[#1E1B59] font-medium text-xl'>{value}</p>
		</div>
	);
};

export default DisplayCard;
