// import Background from '../assets/background.jpg';

import './LogInPage.css';
import Input from '../../components/Input/Input';

import { useEffect, useState, useRef } from 'react';

import Button from '../../components/Button/Button';
import { Navigate, useNavigate } from 'react-router-dom';
import React from 'react';
import { toast } from 'react-toastify';
import { Mixpanel } from '../../util/mixpanel';

const get_user = async (token: string) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};
	const { user, school } = await (
		await fetch(process.env.REACT_APP_BACKEND_URL + '/school-auth/get_user', requestOptions)
	).json();
	return { ...user, school };
};

async function gen_otp(phone_number: string) {
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({ phone_number: phone_number });

	var requestOptions: RequestInit = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	return await fetch(process.env.REACT_APP_BACKEND_URL + '/school-auth/gen_otp', requestOptions);
}

async function verify_otp(otp: string, token: string) {
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({ token: token, otp: otp + '' });

	var requestOptions: RequestInit = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	return await fetch(process.env.REACT_APP_BACKEND_URL + '/school-auth/verify_otp', requestOptions);
}

const add_error = (name: string, error: string, errors: any, set_errors: any) => {
	set_errors({ ...errors, [name]: error });
};

function LogInPage({ user, set_user }: { user: Map<String, String>; set_user: any }) {
	const [validate, set_validate] = useState(false);
	const [phone_number, set_phone_number] = useState('');
	const [otp, set_otp] = useState('');
	const [otp_sent, set_otp_sent] = useState(false);
	const [otp_expire_time, set_otp_expire_time] = useState(180);
	const [resent_opt_time, set_resent_otp_time] = useState(60);
	const [errors, set_errors] = useState({} as any);
	const [error, set_error] = useState(null);
	const [token, set_token] = useState('');
	const [show_otp, set_show_otp] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		let timer: any;
		if (otp_sent) {
			if (otp_expire_time > 0) {
				timer = setInterval(() => {
					set_otp_expire_time(otp_expire_time - 1);
				}, 1000);
			}
			if (otp_expire_time <= 0) {
				window.location.replace('/login');
			}
		}
		return () => {
			clearInterval(timer);
		};
	}, [otp_expire_time, otp_sent]);
	useEffect(() => {
		let timer: any;
		if (otp_sent) {
			if (resent_opt_time > 0) {
				timer = setInterval(() => {
					set_resent_otp_time(resent_opt_time - 1);
				}, 1000);
			}
		}
		return () => {
			clearInterval(timer);
		};
	}, [resent_opt_time, otp_sent]);

	const buttonRef = useRef<any>();
	const otpBtnRef = useRef<any>();

	if (user) return <Navigate to='/'></Navigate>;

	return (
		<div className='login-page min-h-screen flex w-full'>
			<div className='relative z-10 flex flex-1 flex-col bg-[#F8FAFB] py-10 px-4 shadow sm:justify-center md:flex-none md:px-28'>
				<div className='mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0'>
					<div className='flex flex-col'>
						<a aria-label='Home' href='/'>
							<div className='logo'>Edviron</div>
						</a>

						<div className='mt-8'>
							<h2 className='text-lg font-semibold text-gray-900'>Sign in to your account</h2>
						</div>
					</div>

					<div className='mt-5 grid grid-cols-1 gap-y-0'>
						{/* Phone Number */}
						<Input
							name={
								<div className='flex justify-between items-center'>
									Phone Number{' '}
									{otp_sent ? (
										<span
											onClick={() => {
												window.location.replace('/login');
												Mixpanel.track('change_number', {
													change_number: true,
												});
											}}
											className='flex-none ml-3 text-[#6F6AF8] cursor-pointer'
										>
											Change number
										</span>
									) : (
										''
									)}
								</div>
							}
							prefix='+91'
							placeholder='98765-43210'
							maxLength={10}
							onChange={(k: string, v: string) => set_phone_number(v)}
							validator={(res: string) => {
								return res.length === 10 ? null : phone_number ? null : 'Phone Number must be 10 digits long';
							}}
							validate={validate}
							add_error={(name: string, error: string) => {
								add_error(name, error, errors, set_errors);
							}}
							disabled={otp_sent}
							type={''}
							onKeyDown={(event: any) => {
								if (event.keyCode === 13 || event.which === 13) {
									buttonRef?.current.click();
								}
							}}

							
						/>

						{/* Error and Messages */}
						{!otp_sent ? <div className='text-[12px] font-light text-red-500'>{error}</div> : null}
						{otp_sent ? (
							<div className='mt-0'>
								<div className='text-gray-500 flex justify-between text-[12px]  font-light'>
									An OTP has been sent to above mobile number and registered email.
								</div>
							</div>
						) : null}

						{otp_sent ? (
							<>
								<Input
									name='OTP'
									type={show_otp ? 'text' : 'password'}
									postfix={show_otp ? <i className='fa-solid fa-eye-slash'></i> : <i className='fa-solid fa-eye'></i>}
									prefixClick={(e: any) => set_show_otp(!show_otp)}
									onChange={(k: string, v: string) => {
										set_otp(v);
										set_error(null);
									}}
									validator={(res: string) => {
										console.log({ res });
										return res.length === 6
											? null
											: otp.length > 4 || otp.length <= 1
											? null
											: 'OTP must be 6 digits long';
									}}
									validate={validate}
									add_error={(name: string, error: string) => {
										add_error(name, error, errors, set_errors);
									}}
									prefix={''}
									placeholder={''}
									disabled={false}
									onKeyDown={(event: any) => {
										if (event.keyCode === 13 || event.which === 13) {
											otpBtnRef?.current.click();
										}
									}}
								/>
								<div className='flex text-[12px]  font-light items-center justify-between'>
									<p>
										OTP expires in {`${Math.floor(otp_expire_time / 60)} `}
										{otp_expire_time - Math.floor(otp_expire_time / 60) * 60 > 0 &&
											`: ${otp_expire_time - Math.floor(otp_expire_time / 60) * 60 < 10 ? '0' : ''}${
												otp_expire_time - Math.floor(otp_expire_time / 60) * 60
											} `}
										mins
									</p>

									<p className='my-2'>
										Didn’t Receive ?
										<button
											disabled={otp_expire_time > 120}
											onClick={async () => {
												const res = await gen_otp(phone_number);
												if (res.ok) {
													set_otp_expire_time(180);
													set_resent_otp_time(60);
													toast.success('OTP has been sent again', {});
													set_otp_sent(true);
													set_error(null);
													set_token((await res.json()).token);
													set_validate(false);
												} else set_error((await res.json()).message);
											}}
											className='text-[#6F6AF8] ml-2 cursor-pointer disabled:text-gray-500'
										>
											Resend {resent_opt_time > 0 && `(${resent_opt_time}s)`}
										</button>
									</p>
								</div>
							</>
						) : null}
						{otp_sent ? <div className='text-[12px] font-light text-red-500'>{error}</div> : null}

						{/* Sign In Button */}
						<div>
							<Button
								name='Sign In'
								buttonRef={otp_sent ? otpBtnRef : buttonRef}
								onTap={
									otp_sent
										? async () => {
												set_validate(true);
												console.log({ errors });
												for (var error in errors) {
													if (errors[error]) {
														return;
													}
												}
												const res = await verify_otp(otp, token);
												if (res.ok) {
													const response = await res.json();
													localStorage.setItem('token', response.token);
													set_user(await get_user(response.token));
													navigate('/');
													Mixpanel.track('sign_in_button_after_otp', {
														phone_number: phone_number,
														otp: otp,
													});
													// set_user({response.user});
												} else {
													set_error((await res.json()).message);
												}
										  }
										: async () => {
												set_validate(true);
												console.log({ errors });
												for (var error in errors) {
													if (errors[error]) {
														return;
													}
												}
												const res = await gen_otp(phone_number);
												if (res.ok) {
													set_otp_sent(true);
													set_error(null);
													set_token((await res.json()).token);
													set_validate(false);
													Mixpanel.track('sign_in_button', {
														phone_number: phone_number,
													});
												} else set_error((await res.json()).message);
										  }
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LogInPage;
