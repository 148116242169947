import React from 'react';

function FeeConfigDuesSmallLoader() {
	return (
		<div className='flex min-h-screen h-full w-full'>
			<div className='flex flex-col pr-8 w-full'>
				<div className='flex pl-8 w-full flex-1'>
					<div className='flex w-full flex-col'>
						<div className='my-4 grid grid-cols-2 gap-5 w-full'>
							<div className='bg-gray-300 animate-pulse rounded-lg h-36'></div>
							<div className='bg-gray-300 animate-pulse rounded-lg h-36'></div>
						</div>
						<div className='flex justify-between w-full items-center'>
							<div className='space-x-4 w-full flex items-center'>
								<div className='bg-gray-300 animate-pulse max-w-[12rem] mt-3 w-full h-10 rounded-lg'></div>
								<div className='bg-gray-300 animate-pulse max-w-[12rem] mt-3 w-full h-10 rounded-lg'></div>
							</div>
							<div className='space-x-4 w-full flex justify-end items-center'>
								<div className='bg-gray-300 animate-pulse max-w-[12rem] mt-3 w-full h-12 rounded-lg'></div>
								<div className='bg-gray-300 animate-pulse max-w-[12rem] mt-3 w-full h-12 rounded-lg'></div>
							</div>
						</div>
						<div className='bg-gray-300 animate-pulse mt-4 w-full h-full mb-4 rounded-lg'></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeeConfigDuesSmallLoader;
