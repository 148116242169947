import React, { useContext, useEffect, useState } from 'react';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Modal from '../../../../../../components/Modal/Modal';
import Form from '../../../../../../components/Form/Form';
import Input from '../../../../../../components/Input/Input';
import LoadingIcon from '../../../../../../assets/loading-purple.svg';
import { useParams } from 'react-router-dom';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';
import { dashboardContext } from '../../../../Dashboard';
import { Access } from '../../../PortalAccess/AccessEnum';
function AdditionDetails({ setAdditionDetailsSelected, getStudent, updateStudent }: any) {
	const [loader, setLoader] = useState(false);
	const { id } = useParams();
	const { user } = useContext(dashboardContext);
	const [student, setStudent] = useState<any>({});
	const [show_modal, set_show_modal] = useState(false);

	useEffect(() => {
		setAdditionDetailsSelected(true);
		return () => {
			setAdditionDetailsSelected(false);
		}; // eslint-disable-next-line
	}, []);

	return (
		<LoadAndRender
			promise={async () => {
				setStudent(await getStudent(id));
			}}
			height='none'
		>
			{loader ? (
				<div className='m-auto'>
					<img src={LoadingIcon} alt='Loading...' />
				</div>
			) : (
				<div className='ml-4 mt-4 mr-2 w-full border-gray-200 rounded-md shadow p-5 mx-5'>
					<Modal open={show_modal} setOpen={set_show_modal}>
						<Form
							onSubmit={async (data: any) => {
								let studentUpdatedData = {
									religion: data['Religion'] || student[0]?.religion,
									nationality: data['Nationality'] || student[0]?.nationality,
									emergency_contact_number: data['Emergency Contact Number'] || student[0]?.emergency_contact_number,
									mother_tongue: data['Mother Tongue'] || student[0]?.mother_tongue,
								};
								setLoader(true);
								await updateStudent(studentUpdatedData, id);
								setStudent(await getStudent(id));
								setLoader(false);
								set_show_modal(false);
							}}
						>
							<Input value={student[0]?.religion} type='text' name='Religion' add_error={() => {}} />
							<Input value={student[0]?.nationality} type='text' name='Nationality' add_error={() => {}} />
							<Input
								name='Emergency Contact Number'
								value={student[0]?.emergency_contact_number}
								type='number'
								min={0}
								maxLength={10}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								add_error={() => {}}
							/>
							<Input value={student[0]?.mother_tongue} type='text' name='Mother Tongue' add_error={() => {}} />

							{(user.access === Access.SUPER ||
								user.access === Access.ALL_ACCESS ||
								user.access === Access.MANAGEMENT) && (
								<div className='mt-4 text-right'>
									<button
										className={` bg-[#6F6AF8]
							 text-white rounded-lg py-2 px-10 `}
									>
										Update
									</button>
								</div>
							)}
						</Form>
					</Modal>
					<div className='flex justify-between items-center mb-2'>
						<h2 className='text-md text-violet-500 text-lg'>Additional Details</h2>
						<i
							className='fa-regular fa-edit mr-2 text-[#959595] text-[20px] cursor-pointer'
							onClick={() => set_show_modal(!show_modal)}
						></i>
					</div>
					<div className='grid grid-cols-2 gap-5'>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Religion</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.religion || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Nationality</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.nationality || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Emergency Contact Number</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.emergency_contact_number || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Mother Tongue</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.mother_tongue || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Sibling</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.has_sibling ? 'Yes' : 'No'}</div>
						</div>
					</div>
				</div>
			)}
		</LoadAndRender>
	);
}

export default AdditionDetails;
