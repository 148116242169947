import { gql } from '@apollo/client';

export const GET_SCHOOL_DATA = gql`
	{
		school {
			number_of_students
			number_of_sections
			convenience_fee
			instllment_wise_fee_report {
				due_date
				total_amount
				total_amount_paid
				total_students
				total_students_paid
				students_paid {
					applicable_fee_heads {
						discounts {
							_id
							name
							value
							applicable_on
						}
						paid
						to_pay
						fee_head {
							_id
							amount
							name
						}
					}
					discounts {
						_id
						applicable_on
						name
						value
					}
					to_pay
				}
			}
		}
	}
`;
