import React from 'react';
// import crossIcon from '../../assets/cross.svg';
function Notification({ log }: any) {
	if (log === null) return <></>;
	return (
		<div className='mt-[19px] relative flex bg-[#F8FAFB] shadow rounded-[8px] w-auto p-6 h-auto text-left'>
			<p className=' text-[16px]'>{log}</p>
			{/* <div className='absolute right-4 cursor-pointer top-4'>
				<img src={crossIcon} alt='' />
			</div> */}
		</div>
	);
}

export default Notification;
