import React, { useState, useEffect, useContext } from 'react';
import backIcon from '../../../../assets/backicon.svg';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import { Features } from '@headlessui/react/dist/utils/render';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../../components/BackButton/BackButton';
import { dashboardContext } from '../../../../Dashboard';
import { Access } from '../../../PortalAccess/AccessEnum';
import Select from '../../../../../../components/Select/Select';
import Modal from '../../../../../../components/Modal/Modal';
import Input from '../../../../../../components/Input/Input';
import Form from '../../../../../../components/Form/Form';
import Button from '../../../../../../components/Button/Button';
import Switch from '../../../../../../components/Switch/Switch';
import Table from '../../../../../../components/Table/Table';
import DDStatus from './DDStatus';
import { useMutation, useQuery } from '@apollo/client';
import { MARK_CHEQUE_CLEARED, REVERT_CHEQUE_STATUS, MARK_CHEQUE_BOUNCED, GET_CHEQUE_DD_REPORT } from '../../Querries';
import { GET_SCHOOL_DATA } from '../../../Overview/Querries';

const ActionButton = ({ set_bounced_modal, set_cleared_modal, set_revert_modal, status, setToggle }: any) => {
	return (
		<div className='absolute bottom-0 right-5  w-36 rounded-lg shadow bg-[#F8FAFB]'>
			{status === 'PENDING' && (
				<div>
					<div
						onClick={() => {
							setToggle(false);
							set_cleared_modal(true);
						}}
						className='py-2 cursor-pointer hover:bg-slate-200'
					>
						Mark Paid
					</div>
					<hr />
					<div
						onClick={() => {
							setToggle(false);
							set_bounced_modal(true);
						}}
						className=' py-2 text-red-500   cursor-pointer hover:bg-slate-200'
					>
						Bounced
					</div>
				</div>
			)}

			{status !== 'PENDING' && (
				<div
					onClick={() => {
						setToggle(false);
						set_revert_modal(true);
					}}
					className=' py-2 text-red-500   cursor-pointer hover:bg-slate-200'
				>
					Revert
				</div>
			)}
		</div>
	);
};
const StatusReport = ({ data, setData, installments, reports, load }: any) => {
	const [toggle, setToggle] = useState(false);
	const [installmet_name, set_installment_name] = useState('');
	const [show_cleared_modal, set_cleared_modal] = useState(false);
	const [show_bounced_modal, set_bounced_modal] = useState(false);
	const [show_revert_modal, set_revert_modal] = useState(false);
	const [MARK_PAID] = useMutation(MARK_CHEQUE_CLEARED, { refetchQueries: [{ query: GET_CHEQUE_DD_REPORT }] });
	const [REVERT_CHEQUE] = useMutation(REVERT_CHEQUE_STATUS, { refetchQueries: [{ query: GET_CHEQUE_DD_REPORT }] });
	const [MARK_BOUNCED] = useMutation(MARK_CHEQUE_BOUNCED, {
		refetchQueries: [{ query: GET_CHEQUE_DD_REPORT }],
	});

	const [date, set_date] = useState();
	async function handlePaid(id: any, clearing_date: any) {
		const res = MARK_PAID({
			variables: {
				cheque_id: id,
				clearing_date: clearing_date,
			},
		});
		if (res instanceof Error) {
			console.log('something wrong');
		} else {
			set_cleared_modal(false);
			console.log(res);
		}
	}
	async function handleRevert(id: any) {
		const res = await REVERT_CHEQUE({
			variables: {
				cheque_id: id,
			},
		});

		if (res instanceof Error) {
			console.log('something wrong');
		} else {
			set_revert_modal(false);
			console.log(res);
		}
	}
	async function handleBounced(id: any) {
		const res = await MARK_BOUNCED({
			variables: {
				cheque_id: id,
			},
		});

		if (res instanceof Error) {
			console.log('something wrong');
		} else {
			set_bounced_modal(false);
		}
	}
	return (
		<div className=' odd:bg-violet-100  rounded-2xl'>
			<div
				onMouseLeave={() => setToggle(false)}
				className='text-[#1e1b59] w-full grid grid-cols-7 grid-body  p-3 my  odd:border-none even:border border-[#ECEDFB] rounded-lg'
			>
				<Link to={`/student_profile/${data?.student?._id}`}>
					<div className='text-center text-violet-500 text-base'>{data?.student?.name}</div>
				</Link>
				<div className='text-center truncate'>{data.cheque_number}</div>
				<h2 title={data?.cheque_description} className='text-center cursor-text truncate'>
					{data?.cheque_description}
				</h2>

				<div className='text-center truncate'>{new Date(data?.date)?.toLocaleDateString()}</div>
				<div className='text-center truncate'>
					{data?.status === 'CLEARED' ? data?.clearing_date && new Date(data?.clearing_date)?.toLocaleDateString() : ''}
				</div>

				<div className={`text-center ${data?.status === 'CLEARED' ? 'text-green-500' : 'text-red-500'}`}>
					{data?.status}
				</div>
				<div className='p-1 text-center  relative mx-auto '>
					<>
						<i onClick={() => setToggle(!toggle)} className='fa-solid fa-ellipsis-vertical cursor-pointer'></i>
						{toggle && (
							<ActionButton
								handleRevert={handleRevert}
								status={data?.status}
								setToggle={setToggle}
								set_bounced_modal={set_bounced_modal}
								set_cleared_modal={set_cleared_modal}
								set_revert_modal={set_revert_modal}
							/>
						)}
					</>
				</div>
			</div>
			<Modal open={show_cleared_modal} setOpen={set_cleared_modal}>
				<div className='w-11/12 m-auto text-center'>
					<div className='mt-10'>Are you sure you want to Cleared Cheque ?</div>
					<div className='mt-5'>
						<Input
							type='date'
							name='Date'
							onChange={(name: any, value: any) => {
								set_date(value);
							}}
							add_error={(e: any) => {}}
							min='1990-01-01'
							max={`${new Date().getFullYear()}-${
								new Date().getMonth() > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)
							}-${new Date().getDate() > 9 ? new Date().getDate() + 1 : '0' + new Date().getDate()}`}
							required
						/>
					</div>
					<div className='mt-10 mb-10 grid grid-cols-2 gap-4'>
						<button className='py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none'>
							Cancel
						</button>

						<button
							disabled={!date}
							onClick={() => handlePaid(data?._id, date)}
							className={`py-2 text-white px-10 rounded-lg ${date ? 'bg-green-400' : 'bg-green-300'}`}
						>
							Mark Paid
						</button>
					</div>
				</div>
			</Modal>
			<Modal open={show_bounced_modal} setOpen={set_bounced_modal}>
				<div className='w-11/12 m-auto text-center'>
					<div className='mt-10'>Are you sure you want to Mark Bounced?</div>
					<div className='mt-10 mb-10 grid grid-cols-2 gap-4'>
						<button
							onClick={() => set_bounced_modal(false)}
							className='py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none'
						>
							Cancel
						</button>

						<button
							onClick={() => handleBounced(data?._id)}
							className={`py-2 text-white px-10 rounded-lg hover:bg-red-400 bg-red-300`}
						>
							Mark Bounced
						</button>
					</div>
				</div>
			</Modal>
			<Modal open={show_revert_modal} setOpen={set_revert_modal}>
				<div className='w-11/12 m-auto text-center'>
					<div className='mt-10'>Are you sure you want to revert cheque status?</div>
					<div className='mt-10 mb-10 grid grid-cols-2 gap-4'>
						<button
							onClick={() => set_revert_modal(false)}
							className='py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none'
						>
							Cancel
						</button>

						<button
							onClick={() => handleRevert(data?._id)}
							className={`py-2 text-white px-10 rounded-lg hover:bg-red-400 bg-red-300`}
						>
							Revert
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

const ChequeDDStatusReport = () => {
	const [chequeDetails, setChequeDetails] = useState([]);
	const [csv_link, set_csv_link] = useState('');
	const { user } = useContext(dashboardContext);
	const [installments, set_installments] = useState<any>([]);
	const [selected, set_selected] = useState<any>();
	const [selected_cheque, set_selected_cheque] = useState(0);
	const [toggle, setToggle] = useState(false);
	const { data, loading, error } = useQuery(GET_CHEQUE_DD_REPORT);
	console.log(loading);

	useEffect(() => {
		const rows = [
			['Cheque Number', 'Payment Date', 'Status'],
			...(chequeDetails &&
				chequeDetails.map((cheque: any) => {
					return [cheque.cheque_number, new Date(cheque.createdAt).toDateString(), cheque.status];
				})),
		];

		let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
		var encodedUri = encodeURI(csvContent);
		set_csv_link(encodedUri);
	}, []);
	if (loading) return <div>Loading...</div>;
	return (
		<div className='mr-5 mb-5 w-full'>
			<div className='flex mt-8 justify-between px-4 items-center'>
				<BackButton label='Cheque/DD Status Report' />
				<div className='ml-5'>
					<Switch
						options={['Cheque Report', 'DD Report']}
						selected={selected_cheque}
						set_selected={set_selected_cheque}
					></Switch>
				</div>
			</div>
			<p className='text-[#fc4343] text-xs ml-5'>
				All Amounts shown here are calculated without considering fines. Please refer to the Fine Wise Report to view
				the fines applied.*
			</p>
			{selected_cheque === 0 ? (
				<div className='table w-full shadow p-5 rounded-lg my-2 text-[#1e1b59]'>
					<div className='grid grid-cols-7 grid-header p-3 font-semibold bg-[#ECEDFB] rounded-t-lg text-violet-900'>
						<div className='text-center truncate'>Student Name</div>
						<div className='text-center truncate'>Cheque Number</div>
						<div className='text-center truncate'>Account Holder Name</div>
						<div className='text-center truncate'>Payment Date</div>
						<div className='text-center truncate'>Cleared/Bounced Date</div>
						<div className='text-center truncate'>Status</div>
						<div className='text-center truncate'>Actions</div>
					</div>
					{data?.school?.cheques &&
						data?.school?.cheques.map((data: any) => {
							return <StatusReport key={data?._id} load={loading} data={data} />;
						})}
				</div>
			) : (
				<DDStatus reports={data.school.demand_drafts && data.school.demand_drafts} selected={selected} />
			)}
		</div>
	);
};

export default ChequeDDStatusReport;
