import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Switch from '../../../../../components/Switch/Switch';

ChartJS.register(ArcElement, Tooltip, Legend);

const LegendComponent = ({ bgColor, textColor, studentData, countLabel, feeLabel, selected }: any) => {
	return (
		<div>
			<div>
				<div className='flex items-center text-base'>
					<div className={`w-6 h-6  ${bgColor}`}></div>
					{selected ? (
						<div className='py-2 px-4'>
							<div className={textColor + ' font-semibold text-lg'}>{studentData?.count}</div>
							<div className=''>{countLabel}</div>
						</div>
					) : (
						<div className='py-2 px-4'>
							<div className={textColor + ' font-semibold text-lg'}>₹ {studentData?.amount?.toLocaleString('hi')}</div>
							<div className=''>{feeLabel}</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const FeeOverview = ({
	feeLabels,
	studentLabels,
	studentCount,
	studentPaidCount,
	studentDueCount,
	discountAmount,
}: any) => {
	const [selected, set_selected] = useState(0);
	const data = {
		labels: selected ? studentLabels : feeLabels,
		datasets: [
			{
				data: selected
					? [0, 0, studentPaidCount.count, studentDueCount.count]
					: [0, 0, studentPaidCount.amount, studentDueCount.amount],
				backgroundColor: ['gray', '#1C4397', '#2EB418', '#FC4343'],
				hoverBackgroundColor: ['gray', '#1C4397', '#2EB418', '#FC4343'],
				hoverOffset: 3,
			},
		],
	};
	const options: any = {
		responsive: true,
		cutout: '60%',

		plugins: {
			emptyDoughnut: {
				color: 'rgba(255, 128, 0, 0.5)',
				width: 0,
				radiusDecrease: 20,
			},
			legend: {
				display: false,
				position: 'left',
				labels: {
					boxWidth: 20,
					boxHeight: 20,
					font: {
						size: 20,
						weight: 'bold',
					},
				},
			},
		},
	};

	return (
		<div className='px-6 rounded-lg  border-gray-200 shadow py-2'>
			<div>
				<div className='py-2 flex justify-between my-3'>
					<div className='text-[20px]'>Fee Amount Overview</div>
					<div className='my-auto'>
						<Switch options={['Amount', 'Count']} selected={selected} set_selected={set_selected} />
					</div>
				</div>
				<div className='p-0 flex  justify-between my-3'>
					<div className='text-[20px] '>
						<LegendComponent
							bgColor={'bg-gray-500'}
							textColor={'text-gray'}
							studentData={studentCount}
							countLabel={'Total Student count'}
							feeLabel={'Applied Fee'}
							selected={selected}
						/>

						{/* <LegendComponent
							bgColor={'bg-[#1C4397]'}
							textColor={'text-[#1C4397]'}
							studentData={discountAmount}
							countLabel={'Dicount Student count'}
							feeLabel={'Discount Fee'}
							selected={selected}
						/> */}

						<LegendComponent
							bgColor={'bg-[#2EB418]'}
							textColor={'text-[#2EB418]'}
							studentData={studentPaidCount}
							countLabel={'Students with no dues'}
							feeLabel={'Paid Fee'}
							selected={selected}
						/>

						<LegendComponent
							bgColor={'bg-[#FC4343]'}
							textColor={'text-[#FC4343]'}
							studentData={studentDueCount}
							countLabel={'Students with dues'}
							feeLabel={'Due Fee'}
							selected={selected}
						/>
					</div>
					<div className='h-48 my-auto mr-5'>
						<Doughnut data={data} options={options} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeeOverview;
