export enum Access {
	SUPER = 'super',
	ALL_ACCESS = 'all_access',
	MANAGEMENT = 'management',
	STAFF = 'staff',
}

export enum PaymentMode {
	CASH = 'CASH',
	CHEQUE = 'CHEQUE',
	DD = 'DD',
	ONLINE = 'ONLINE',
}
