import React, { useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import Switch from '../../../../../components/Switch/Switch';

const get_month_name = (month: any) => {
	console.log(typeof month.slice(5));
	let number = month.slice(5);
	switch (number) {
		case '1':
			return 'Jan';
		case '2':
			return 'Feb';
		case '3':
			return 'Mar';
		case '4':
			return 'Apr';
		case '5':
			return 'May';
		case '6':
			return 'Jun';
		case '7':
			return 'July';
		case '8':
			return 'Aug';
		case '9':
			return 'Sep';
		case '10':
			return 'Oct';
		case '11':
			return 'Nov';
		case '12':
			return 'Dec';
	}
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const FeeCollected = ({ feeData, labels }: any) => {
	const [selected, set_selected] = useState(0);

	const data = {
		labels: feeData?.map((d: any) => d.installmentdate.slice(4, 7) + ' ' + d.installmentdate.slice(11)),
		datasets: [
			{
				label: selected ? 'Collected Count' : 'Amount Collected',
				data: !selected
					? feeData.map((fee: any) => fee.total_amount_paid)
					: feeData.map((fee: any) => fee.total_students_paid),
				fill: false,
				tension: 0.1,
				pointBorderColor: 'blue',
				pointBackgroundColor: 'blue',
				borderColor: 'green',
			},
		],
	};
	const options = {
		elements: {
			line: {
				backgroundColor: 'green',
				borderWidth: 1,
			},
		},
		dataset: {
			line: {
				display: false,
			},
		},
		plugins: { legend: { display: false } },
		scales: {
			x: {
				categorySpacing: 0,
				categoryPercentage: 0,
				offset: true,
				beginAtZero: false,
				grid: {
					display: false,
				},
				border: {
					display: false,
				},
				ticks: {
					color: '#1E1B59',
					autoSkip: false,
					font: {
						family: 'Lexend',
						size: 15,
						weight: '500',
					},
				},
			},
			y: {
				offset: true,
				beginAtZero: false,
				grid: {
					display: false,
				},
				border: {
					display: false,
				},
				ticks: {
					autoSkip: true,
					maxTicksLimit: 4,

					color: '#1E1B59',
					font: {
						family: 'Lexend',
						size: 15,
						weight: '500',
					},
					callback: function (value: any, index: any, values: any) {
						if (value >= 1000 && value < 100000) {
							return `₹${value / 1000} K`;
						} else if (value >= 100000) {
							return `₹${value / 100000} L`;
						} else {
							return value;
						}
					},
				},
			},
		},
		plugin: {
			legend: {
				display: false,
			},
		},
	};

	return (
		<div className='ml-2 py-2 px-6 rounded-lg  border-gray-200 shadow'>
			<div>
				<div className='flex justify-between my-3'>
					<div className='text-[20px] '>Fee Collected</div>
					<div className='my-auto'>
						<Switch options={['Amount', 'Count']} selected={selected} set_selected={set_selected} />
					</div>
				</div>
			</div>
			<div className='h-56 mx-auto w-full'>
				<Bar options={options} data={data} />
			</div>
		</div>
	);
};

export default FeeCollected;
