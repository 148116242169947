import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Input/Input';

const AddEditSectionModal = ({ setSectionData }: any) => {
	const [className, setClassName] = useState('');
	const [section, setSection] = useState('');

	useEffect(() => {
		console.log(className);
		setSectionData({
			class: className,
			section: section,
		});
	}, [className, section]);

	return (
		<div className='w-full mx-auto mt-5'>
			<div className='flex items-center  m-auto'>
				<div className='w-full'>
					<Input
						type='text'
						name='Class'
						add_error={() => {}}
						value={className}
						onChange={(k: string, v: string) => setClassName(v)}
						placeholder='Enter class name'
					/>
				</div>
			</div>
			<div className='flex items-center  m-auto'>
				<div className='w-full'>
					<Input
						type='text'
						name='Section'
						add_error={() => {}}
						value={section}
						onChange={(k: string, v: string) => setSection(v)}
						placeholder='Enter section name'
					/>
				</div>
			</div>
		</div>
	);
};

export default AddEditSectionModal;
