import React from 'react';

function FeeConfigFineSmallLoader() {
	return (
		<div className='flex min-h-screen h-full w-full'>
			<div className='flex flex-col h-full pr-8 w-full'>
				<div className='flex pl-8 w-full flex-1'>
					<div className='flex w-full flex-col'>
						<div className='flex justify-end items-center'>
							<div className='bg-gray-300 animate-pulse max-w-[20rem] mt-3 w-full h-10 rounded-lg'></div>
						</div>
						<div className='bg-gray-300 animate-pulse mt-4 w-full h-full mb-4 rounded-lg'></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeeConfigFineSmallLoader;
