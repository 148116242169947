import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import LoadingIcon from '../../../../../../assets/loading-purple.svg';

import Input from '../../../../../../components/Input/Input';

import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';
import { dashboardContext } from '../../../../Dashboard';
import { Access } from '../../../PortalAccess/AccessEnum';
import Modal from '../../../../../../components/Modal/Modal';
import Form from '../../../../../../components/Form/Form';

const AddressDetails = ({ setAddressSelected, getStudent, updateStudent }: any) => {
	const [loader, setLoader] = useState(false);
	const { id } = useParams();
	const { user } = useContext(dashboardContext);
	const [student, setStudent] = useState<any>({});
	const [show_modal, set_show_modal] = useState(false);

	useEffect(() => {
		setAddressSelected(true);
		return () => {
			setAddressSelected(false);
		}; // eslint-disable-next-line
	}, []);
	console.log(student);

	return (
		<LoadAndRender
			promise={async () => {
				setStudent(await getStudent(id));
			}}
			height='none'
		>
			{loader ? (
				<div className='m-auto'>
					<img src={LoadingIcon} alt='Loading...' />
				</div>
			) : (
				<div className='ml-4 mt-4 mr-2 w-full border-gray-200 rounded-md shadow p-5 mx-5'>
					<Modal open={show_modal} setOpen={set_show_modal}>
						<Form
							onSubmit={async (data: any) => {
								let address = {
									address_line1: data['Address Line 1'] || student[0]?.address_line1,
									address_line2: data['Address Line 2'] || student[0]?.address_line2,
									street: data['Street'] || student[0]?.street,
									state: data['State'] || student[0]?.state,
									city: data['City'] || student[0]?.city,
									pin: data['Pin'] || student[0]?.pin,
									country: data['Country'] || student[0]?.country,
								};
								setLoader(true);
								await updateStudent(address, id);
								setLoader(false);
								setStudent(await getStudent(id));
								set_show_modal(false);
							}}
						>
							<Input value={student[0]?.address?.street} type='text' name='Street' add_error={() => {}} />
							<Input
								value={student[0]?.address?.address_line1}
								type='text'
								name='Address Line 1'
								add_error={() => {}}
							/>
							<Input
								value={student[0]?.address?.address_line2}
								type='text'
								name='Address Line 2'
								add_error={() => {}}
							/>
							<Input value={student[0]?.address?.city} type='text' name='City' add_error={() => {}} />
							<Input value={student[0]?.address?.state} type='text' name='State' add_error={() => {}} />
							<Input value={student[0]?.address?.country} type='text' name='Country' add_error={() => {}} />
							<Input
								value={student[0]?.address?.pin}
								type='number'
								name='Pin'
								add_error={() => {}}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								maxLength={6}
							/>
							{(user.access === Access.SUPER ||
								user.access === Access.ALL_ACCESS ||
								user.access === Access.MANAGEMENT) && (
								<div className='mt-4 text-right'>
									<button
										className={` bg-[#6F6AF8]
							 text-white rounded-lg py-2 px-10 `}
									>
										Update
									</button>
								</div>
							)}
						</Form>
					</Modal>
					<div className='flex justify-between items-center mb-2'>
						<h2 className='text-md text-violet-500 text-lg'>Address Details</h2>
						<i
							className='fa-regular fa-edit mr-2 text-[#959595] text-[20px] cursor-pointer'
							onClick={() => set_show_modal(!show_modal)}
						></i>
					</div>
					<div className='grid grid-cols-2 gap-5'>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Address Line 1</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.address?.address_line1 || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Address Line 2</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.address?.address_line2 || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Street</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.address?.street || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>City</div>
							<div className='text-base text-[#1E1B59] uppercase'>{student[0]?.address?.city || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>State</div>
							<div className='text-base text-[#1E1B59] uppercase'>{student[0]?.address?.state || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Country</div>
							<div className='text-base text-[#1E1B59] uppercase'>{student[0]?.address?.country || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Pin</div>
							<div className='text-base text-[#1E1B59] uppercase'>{student[0]?.address?.pin || 'NA'}</div>
						</div>
					</div>
				</div>
			)}
		</LoadAndRender>
	);
};

export default AddressDetails;
