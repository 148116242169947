import React, { useState } from 'react';
import Table from '../../../../../../components/Table/Table';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Modal from '../../../../../../components/Modal/Modal';
import Input from '../../../../../../components/Input/Input';
import { GET_CHEQUE_DD_REPORT, MARK_DD_PAID, REVERT_DD_STATUS } from '../../Querries';
import { useMutation } from '@apollo/client';
import { GET_SCHOOL_DATA } from '../../../Overview/Querries';
import { Link } from 'react-router-dom';

const ActionButton = ({ set_bounced_modal, set_cleared_modal, set_revert_modal, status, setToggle }: any) => {
	return (
		<div className='absolute bottom-0 right-5  w-36 rounded-lg shadow bg-[#F8FAFB]'>
			{status === 'PENDING' && (
				<div>
					<div
						onClick={() => {
							setToggle(false);
							set_cleared_modal(true);
						}}
						className='py-2 cursor-pointer hover:bg-slate-200'
					>
						Mark Paid
					</div>
					<hr />
					<div
						onClick={() => {
							setToggle(false);
							set_bounced_modal(true);
						}}
						className=' py-2 text-red-500   cursor-pointer hover:bg-slate-200'
					>
						Bounced
					</div>
				</div>
			)}

			{status !== 'PENDING' && (
				<div
					onClick={() => {
						setToggle(false);
						set_revert_modal(true);
					}}
					className=' py-2 text-red-500   cursor-pointer hover:bg-slate-200'
				>
					Revert
				</div>
			)}
		</div>
	);
};

const StatusReport = ({ data, setData, installments, reports }: any) => {
	const [toggle, setToggle] = useState(false);
	const [show_cleared_modal, set_cleared_modal] = useState(false);
	const [date, set_date] = useState();
	const [show_revert_modal, set_revert_modal] = useState(false);
	const [MARK_PAID] = useMutation(MARK_DD_PAID, { refetchQueries: [{ query: GET_CHEQUE_DD_REPORT }] });
	const [REVERT_DD] = useMutation(REVERT_DD_STATUS, { refetchQueries: [{ query: GET_CHEQUE_DD_REPORT }] });
	async function handlePaid(id: any, date: any) {
		const res = await MARK_PAID({
			variables: {
				clearing_date: date,
				dd_id: id,
			},
		});

		if (res instanceof Error) {
			console.log('something wrong');
		} else {
			set_cleared_modal(false);
		}
	}

	async function handleRevert(id: any) {
		const res = await REVERT_DD({
			variables: {
				dd_id: id,
			},
		});

		if (res instanceof Error) {
			console.log('something wrong');
		} else {
			set_revert_modal(false);
		}
	}

	return (
		<div className=' odd:bg-violet-100  rounded-2xl'>
			<div
				onMouseLeave={() => setToggle(false)}
				className='text-[#1e1b59] w-full grid grid-cols-7 grid-body  p-3 my  odd:border-none even:border border-[#ECEDFB] rounded-lg'
			>
				<Link to={`/student_profile/${data?.student?._id}`}>
					<div className='text-center text-violet-500 text-base'>{data?.student?.name}</div>
				</Link>
				<div className='text-center truncate'>{data.dd_number}</div>
				<h2 title={data?.dd_description} className='text-center cursor-text truncate'>
					{data?.dd_description}
				</h2>

				<div className='text-center truncate'>{new Date(data?.date)?.toLocaleDateString()}</div>
				<div className='text-center truncate'>
					{data?.status === 'CLEARED' ? data?.clearing_date && new Date(data?.clearing_date)?.toLocaleDateString() : ''}
				</div>

				<div className={`text-center ${data?.status === 'CLEARED' ? 'text-green-500' : 'text-red-500'}`}>
					{data?.status}
				</div>
				<div className='p-1 text-center  relative mx-auto '>
					<>
						<i onClick={() => setToggle(!toggle)} className='fa-solid fa-ellipsis-vertical cursor-pointer'></i>
						{toggle && (
							<ActionButton
								status={data?.status}
								setToggle={setToggle}
								set_revert_modal={set_revert_modal}
								set_cleared_modal={set_cleared_modal}
							/>
						)}
					</>
				</div>
			</div>
			<Modal open={show_cleared_modal} setOpen={set_cleared_modal}>
				<div className='w-11/12 m-auto text-center'>
					<div className='mt-10'>Are you sure you want to Cleared Cheque ?</div>
					<div className='mt-5'>
						<Input
							type='date'
							name='Date'
							onChange={(name: any, value: any) => {
								set_date(value);
							}}
							add_error={(e: any) => {}}
							min='1990-01-01'
							max={`${new Date().getFullYear()}-${
								new Date().getMonth() > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)
							}-${new Date().getDate() > 9 ? new Date().getDate() + 1 : '0' + new Date().getDate()}`}
							required
						/>
					</div>
					<div className='mt-10 mb-10 grid grid-cols-2 gap-4'>
						<button className='py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none'>
							Cancel
						</button>

						<button
							disabled={!date}
							onClick={() => handlePaid(data?._id, date)}
							className={`py-2 text-white px-10 rounded-lg ${date ? 'bg-green-400' : 'bg-green-300'}`}
						>
							Mark Paid
						</button>
					</div>
				</div>
			</Modal>
			<Modal open={show_revert_modal} setOpen={set_revert_modal}>
				<div className='w-11/12 m-auto text-center'>
					<div className='mt-10'>Are you sure you want to revert DD status?</div>
					<div className='mt-10 mb-10 grid grid-cols-2 gap-4'>
						<button
							onClick={() => set_revert_modal(false)}
							className='py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none'
						>
							Cancel
						</button>

						<button
							onClick={() => handleRevert(data?._id)}
							className={`py-2 text-white px-10 rounded-lg hover:bg-red-400 bg-red-300`}
						>
							Revert
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};
const DDStatus = ({ reports, selected }: any) => {
	return (
		<div className='table w-full shadow p-5 rounded-lg my-2 text-[#1e1b59]'>
			<div className='grid grid-cols-7 grid-header p-3 font-semibold bg-[#ECEDFB] rounded-t-lg text-violet-900'>
				<div className='text-center truncate'>Student Name</div>
				<div className='text-center truncate'>DD Number</div>
				<div className='text-center truncate'>Account Holder Name</div>
				<div className='text-center truncate'>Payment Date</div>
				<div className='text-center truncate'>Cleared Date</div>
				<div className='text-center truncate'>Status</div>
				<div className='text-center truncate'>Actions</div>
				{/* <div className='text-center truncate'>Student Name</div>
					<div className='text-center truncate'>Class</div>
					<div className='text-center truncate'>Installment</div> */}
			</div>
			{reports.map((data: any) => {
				return <StatusReport key={data?._id} data={data} />;
			})}
		</div>
	);
};
export default DDStatus;
