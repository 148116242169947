import React, { useContext, useState } from 'react';
import Table from '../../../../../../components/Table/Table';
import { Link } from 'react-router-dom';

import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import SelectClassSection from '../../../../../../components/SelectClassSection/SelectClassSection';

import CollectFeeModal from './CollectFeeModal';
import { useQuery } from '@apollo/client';
import { GET_SECTION_DATA } from './Queries';
import { dashboardContext } from '../../../../Dashboard';
import { Mixpanel } from '../../../../../../util/mixpanel';

const get_collection = async (section_id: string) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(
		process.env.REACT_APP_BACKEND_URL + '/api/fee-collection/section/' + section_id,
		requestOptions,
	);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

// const get_amount = async (arr: any, id: any) => {
// 	let myHeaders = new Headers();
// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
// 	var requestOptions: RequestInit = {
// 		method: 'GET',
// 		headers: myHeaders,
// 		redirect: 'follow',
// 	};

// 	const res = await fetch(
// 		process.env.REACT_APP_BACKEND_URL +
// 			`/api/fee-collection/calculate_amount?student_id=${id}&installments=${JSON.stringify(arr)}`,
// 		requestOptions,
// 	);
// 	if (res.ok) {
// 		return await res.json();
// 	} else {
// 		const message = await res.json();
// 		if (message.statusCode === 401) {
// 			localStorage.removeItem('token');
// 			window.location.reload();
// 		}
// 	}
// };
// const getFineByStudentId = async (id: any) => {
// 	console.log({ id });
// 	let myHeaders = new Headers();
// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
// 	var requestOptions: RequestInit = {
// 		method: 'GET',
// 		headers: myHeaders,
// 		redirect: 'follow',
// 	};

// 	const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/student/fine?id=${id}`, requestOptions);
// 	if (res.ok) {
// 		return await res.json();
// 	} else {
// 		const message = await res.json();
// 		if (message.statusCode === 401) {
// 			localStorage.removeItem('token');
// 			window.location.reload();
// 		}
// 	}
// };

const CollectOverview = ({ _loading }: any) => {
	const [_class, set_class] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [student, setStudent] = useState<any>(null);
	// const [students, setStudents] = useState<any>([]);
	const [section, set_section] = useState('');
	const [search_input, set_search_input] = useState('');
	const { user } = useContext(dashboardContext);

	const {
		data: section_data,
		loading,
		error,
	} = useQuery(GET_SECTION_DATA, {
		variables: { class: _class, section: section },
	});

	console.log(section_data?.school?.section_fee_report);

	if (error) console.log({ error });

	const students =
		!loading &&
		section_data?.school?.section_fee_report.map((fr: any) => {
			return {
				_id: fr.student._id,
				name: fr.student.name,
				father_name: fr.student.father_name,
				school_generated_id: fr.student.school_generated_id,
				total: fr.total_amount,
				paid: fr.total_amount_paid,
				due: fr.total_amount - fr.total_amount_paid,
				category: fr.student.category,
				class: fr.student.class,
				dob: fr.student.dob,
				gender: fr.student.gender,
				section: fr.student.section,
				phone_number: fr.student.phone_number,
				school: fr.student.school,
				previous_session_dues: fr?.student?.previous_session_dues,
				logo_url: fr.student.school.logo_url,
			};
		});

	return (
		<div>
			<div className='w-1/2'>
				<SelectClassSection
					className='flex'
					onChange={(s: any) => {
						set_section(s._id);
						set_class(s.class);
						set_section(s.section);
					}}
				></SelectClassSection>
			</div>
			<CollectFeeModal
				student={student}
				setStudents={(s: any) => {}}
				get_collection={get_collection}
				showModal={showModal}
				setShowModal={setShowModal}
				students={students}
			/>

			{section && (
				<LoadAndRender loading={loading}>
					{students && (
						<div className='w-full'>
							{/* <h2>this div may need to remove after some fixes</h2> */}
							<div className='grid grid-cols-7'>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
							<Table
								heading={
									<div className='bg-[#817DFF] mt-2 max-w-md h-12 mb-2 ml-2 items-center bg-opacity-10 flex py-3 px-6 rounded-[24px]'>
										<i className='text-[#1E1B59] text-opacity-50 fa-solid fa-magnifying-glass'></i>
										<input
											onChange={(e) => set_search_input(e.target.value)}
											type='text'
											placeholder='Search by student name, enrolment number'
											className='ml-4 bg-transparent focus:outline-none w-full placeholder:font-normal placeholder:text-base'
										/>
									</div>
								}
								data={[
									['Student Details', 'Father Name', 'Roll No.', 'Total Amount', 'Total Paid', 'Total Due', 'Action'],

									...students
										.filter((s: any) =>
											[s.name, s.father_name, s.school_generated_id]
												.join(',')
												.toLowerCase()
												.includes(search_input.toLowerCase()),
										)
										.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
										.map((r: any) => [
											<Link to={'/student_profile/' + r._id}>
												<span className='text-base text-violet-500'>{r.name}</span>
											</Link>,
											r.father_name,
											r.school_generated_id,
											<h2>{'₹ ' + r.total.toLocaleString('hi')}</h2>,
											<h2 className='text-[#2EB418]'>{'₹ ' + r.paid.toLocaleString('hi')}</h2>,
											<h2 className='text-[#FC4343]'>{'₹ ' + r.due.toLocaleString('hi')}</h2>,

											<button
												onClick={() => {
													setShowModal(true);
													setStudent(r);
													Mixpanel.track('collect_fee_button', {
														user_name: user?.name,
														schoolId: r.school?.id,
														school: r.school?.name,
														studentId: r?._id,
														studentName: r?.name,
													});
												}}
												className={` py-1.5 text-[#1E1B59] text-base bg-indigo-200 px-4 rounded-[8px] `}
											>
												Collect Fee
											</button>,
										]),
								]}
								footer={<div></div>}
							/>
						</div>
					)}
				</LoadAndRender>
			)}
		</div>
	);
};

export default CollectOverview;
