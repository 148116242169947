import React, { useEffect, useState } from 'react';
import SelectClassSection from '../../../../../../components/SelectClassSection/SelectClassSection';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Table from '../../../../../../components/Table/Table';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../../components/BackButton/BackButton';
import { gql, useQuery } from '@apollo/client';
import Modal from '../../../../../../components/Modal/Modal';
function Defaulters({ user, school }: any) {
	// const [students, setStudents] = useState<any>([]);
	const [section_id, set_section_id] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [clas, set_clas] = useState(null);
	const [unpaidInstallments, setUnPaidInstallments] = useState<any>(null);
	const [section, set_section] = useState(null);
	const [search_input, set_search_input] = useState('');
	const {
		loading,
		data: students,
		error,
	} = useQuery(gql`
	{
		school{
		  section_fee_report(class:"${clas}", section:"${section}"){
			total_amount
			total_amount_paid
			installments {
                to_pay
                due_date
            }
			student{
			  name
			  father_name
			  school_generated_id
			  _id
			}
		  }
		  }
	  }`);

	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();

	return (
		<div>
			<BackButton label='Defaulters Report' />
			<div className='w-1/2'>
				<SelectClassSection
					className='flex'
					onChange={(s: any) => {
						set_clas(s.class);
						set_section(s.section);
					}}
				></SelectClassSection>
			</div>
			<Modal className='max-w-2xl' open={showModal} setOpen={setShowModal}>
				{unpaidInstallments && (
					<Table
						heading={'Unpaid Installments'}
						data={[
							['Installment', 'Amount'],
							...unpaidInstallments
								?.filter((installment: any) => {
									const dueDate = new Date(installment.due_date);
									return (
										dueDate.getFullYear() === currentYear &&
										dueDate.getMonth() <= currentMonth &&
										installment.to_pay !== 0
									);
								})
								?.map((installment: any) => [
									<div>{new Date(installment.due_date).toDateString()}</div>,
									<div>₹ {installment.to_pay.toLocaleString()}</div>,
								]),
						]}
						footer={
							<div className='grid grid-cols-2 grid-header p-3 font-semibold m-5 bg-[#ECEDFB] text-center text-violet-900'>
								<div className='font-bold'>Total: </div>
								{'₹ ' +
									unpaidInstallments
										?.filter((installment: any) => {
											const dueDate = new Date(installment.due_date);
											return (
												dueDate.getFullYear() === currentYear &&
												dueDate.getMonth() <= currentMonth &&
												installment.to_pay !== 0
											);
										})
										?.map((installment: any) => installment.to_pay)
										.reduce((a: any, b: any) => a + b, 0)
										.toLocaleString()}
							</div>
						}
					/>
				)}
			</Modal>
			{
				<LoadAndRender loading={loading}>
					{students && (
						<div className='w-full'>
							<Table
								heading={
									<div className='bg-[#817DFF] mt-2 max-w-md h-12 mb-2 ml-2 items-center bg-opacity-10 flex py-3 px-6 rounded-[24px]'>
										<i className='text-[#1E1B59] text-opacity-50 fa-solid fa-magnifying-glass'></i>
										<input
											onChange={(e) => set_search_input(e.target.value)}
											type='text'
											placeholder='Search by student name, enrolment number'
											className='ml-4 bg-transparent focus:outline-none w-full placeholder:font-normal placeholder:text-base'
										/>
									</div>
								}
								data={[
									['Student Details', 'Father Name', 'School Generated Id', 'Total Fee', 'Total Paid', 'Total Unpaid'],

									...JSON.parse(JSON.stringify(students))
										.school.section_fee_report.sort((a: any, b: any) => {
											const fee_diff = a.total_amount - a.total_amount_paid - b.total_amount + b.total_amount_paid;
											return fee_diff === 0 ? b.student.name.localeCompare(a.student.name) : fee_diff;
										})
										.reverse()
										.filter((s: any) =>
											[s.student.name, s.student.father_name, s.student.school_generated_id]
												.join(',')
												.toLowerCase()
												.includes(search_input.toLowerCase()),
										)
										.map((r: any) => [
											<Link to={'/student_profile/' + r.student._id}>
												<span className='text-base text-violet-500'>{r.student.name}</span>
											</Link>,
											r.student.father_name,
											r.student.school_generated_id,
											<h2>{'₹ ' + r.total_amount.toLocaleString('hi')}</h2>,
											<h2 className='text-[#2EB418]'>{'₹ ' + r.total_amount_paid.toLocaleString('hi')}</h2>,
											<h2
												className='text-[#FC4343] cursor-pointer'
												onClick={() => {
													setShowModal(true);
													setUnPaidInstallments(r.installments);
												}}
											>
												{'₹ ' +
													r.installments
														?.filter((installment: any) => {
															const dueDate = new Date(installment.due_date);
															return (
																dueDate.getFullYear() === currentYear &&
																dueDate.getMonth() <= currentMonth &&
																installment.to_pay !== 0
															);
														})
														?.map((installment: any) => installment.to_pay)
														.reduce((a: any, b: any) => a + b, 0)
														.toLocaleString()}
											</h2>,
										]),
								]}
								footer={<div></div>}
							/>
						</div>
					)}
				</LoadAndRender>
			}
		</div>
	);
}

export default Defaulters;
