import React, { useState } from 'react';
import LoadAndRender from '../../../../components/LoadAndRender/LoadAndRender';
import SchoolList from './pages/SchoolList';
import { Routes, Route } from 'react-router-dom';
import SchoolDetails from './pages/SchoolDetails';
import EditSchoolDetails from './pages/EditSchoolDetails';
import { dashboardContext } from '../../Dashboard';

// const get_schools = async () => {
// 	var myHeaders = new Headers();
// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

// 	var requestOptions: RequestInit = {
// 		method: 'GET',
// 		headers: myHeaders,
// 		redirect: 'follow',
// 	};

// 	return await (await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school', requestOptions)).json();
// };
const SchoolProfile = ({}: any) => {
	return (
		<LoadAndRender promise={async () => {}}>
			<div className='w-full p-5'>
				<div>
					<Routes>
						<Route path='/' element={<SchoolList />} />
						<Route path='/details' element={<SchoolDetails />} />
						<Route path='/details/edit' element={<EditSchoolDetails />} />
					</Routes>
				</div>
			</div>
		</LoadAndRender>
	);
};

export default SchoolProfile;
