import React, { useContext, useEffect, useState } from 'react';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Modal from '../../../../../../components/Modal/Modal';
import Form from '../../../../../../components/Form/Form';
import { useParams } from 'react-router-dom';
import { dashboardContext } from '../../../../Dashboard';
import LoadingIcon from '../../../../../../assets/loading-purple.svg';
import Input from '../../../../../../components/Input/Input';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';
import { Access } from '../../../PortalAccess/AccessEnum';
function ParentsBankDetails({ setParentsBankSelected, getStudent, updateStudent }: any) {
	const [loader, setLoader] = useState(false);
	const { id } = useParams();
	const { user } = useContext(dashboardContext);

	const [student, setStudent] = useState<any>({});
	const [show_modal, set_show_modal] = useState(false);
	useEffect(() => {
		setParentsBankSelected(true);
		return () => {
			setParentsBankSelected(false);
		}; // eslint-disable-next-line
	}, []);
	return (
		<LoadAndRender
			promise={async () => {
				setStudent(await getStudent(id));
			}}
			height='none'
		>
			{loader ? (
				<div className='m-auto'>
					<img src={LoadingIcon} alt='Loading...' />
				</div>
			) : (
				<div className='ml-4 mt-4 mr-2 w-full border-gray-200 rounded-md shadow p-5 mx-5'>
					<Modal open={show_modal} setOpen={set_show_modal}>
						<Form
							onSubmit={async (data: any) => {
								let studentData = {
									bank_name: data['Bank Name'],
									bank_account_number: data['Bank Account Number'],
									ifsc_code: data['Bank Ifsc Code'],
									account_holder_name: data['Bank Holder Name'],
								};
								setLoader(true);
								await updateStudent(studentData, id);
								setStudent(await getStudent(id));
								setLoader(false);
								set_show_modal(false);
							}}
						>
							<Input value={student[0]?.bank_name} type='text' name='Bank Name' add_error={() => {}} />
							<Input
								value={student[0]?.bank_account_number}
								type='test'
								maxLength={17}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								name='Bank Account Number'
								add_error={() => {}}
							/>
							<Input value={student[0]?.account_holder_name} type='text' name='Bank Holder Name' add_error={() => {}} />
							<Input
								value={student[0]?.ifsc_code}
								type='number'
								maxLength={11}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								name='Bank Ifsc Code'
								add_error={() => {}}
							/>

							{(user.access === Access.SUPER ||
								user.access === Access.ALL_ACCESS ||
								user.access === Access.MANAGEMENT) && (
								<div className='mt-4 text-right'>
									<button
										className={` bg-[#6F6AF8]
							 text-white rounded-lg py-2 px-10 `}
									>
										Update
									</button>
								</div>
							)}
						</Form>
					</Modal>
					<div className='flex justify-between items-center mb-2'>
						<h2 className='text-md text-violet-500 text-lg'>Parents Bank Details</h2>
						<i
							className='fa-regular fa-edit mr-2 text-[#959595] text-[20px] cursor-pointer'
							onClick={() => set_show_modal(!show_modal)}
						></i>
					</div>

					<div className='grid grid-cols-2 gap-5'>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Bank Name</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.bank_name || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Bank Account Number</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.bank_account_number || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Bank Holder Name</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.account_holder_name || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Bank Ifsc Code</div>
							<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.ifsc_code || 'NA'}</div>
						</div>
					</div>
				</div>
			)}
		</LoadAndRender>
	);
}

export default ParentsBankDetails;
