import React from 'react';

function FeeCollectionSmallLoader() {
	return (
		<div className='flex w-full'>
			<div className='flex flex-col pr-8 w-full'>
				<div className='flex pl-8 w-full flex-1'>
					<div className='flex w-full flex-col'>
						<div className='bg-gray-300 animate-pulse max-w-[15rem] mt-3 w-full h-10 rounded-lg'></div>
						<div className='grid grid-cols-3 gap-5 mt-5'>
							<div className='bg-gray-300 animate-pulse w-full h-48 rounded-lg'></div>
							<div className='bg-gray-300 animate-pulse w-full h-48 rounded-lg'></div>
							<div className='bg-gray-300 animate-pulse w-full h-48 rounded-lg'></div>
						</div>
						<div className='bg-gray-300 mt-4 animate-pulse max-w-xl w-full h-[156px] rounded-lg'></div>

						<div className='bg-gray-300 animate-pulse  mt-10 w-full h-[21rem] rounded-lg'></div>
					</div>
					<div className='relative'>
						<div className='rounded-md w-96 h-full p-4   sticky top-0'>
							<div className='min-h-full rounded-lg bg-gray-300 animate-pulse'></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeeCollectionSmallLoader;
