import React, { useState } from 'react';

import BackButton from '../../../../../../components/BackButton/BackButton';
import Table from '../../../../../../components/Table/Table';

import { DiscountType } from '../../../FeeConfig/pages/DiscountsTab/components/CreateGroupDiscount';
import { Link } from 'react-router-dom';
import InvoicePDF from '../../../FeeCollection/pages/FeeOverview/InvoicePDF';
import Modal from '../../../../../../components/Modal/Modal';
import { useQuery } from '@apollo/client';
import { GET_AVAILED_DISCOUNT_REPORT } from '../../Querries';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Pagination from '../../../../../../components/Pagination/Pagination';
const DiscountReport = () => {
	const [open_modal, set_modal] = useState(false);
	const [transaction, setTransaction] = useState<any>(null);
	const [page, setPage] = useState(1);
	const { data, loading, error } = useQuery(GET_AVAILED_DISCOUNT_REPORT, {
		variables: {
			page: page,
		},
	});

	if (loading) return <div>Loading...</div>;
	return (
		<div className='w-full'>
			<BackButton label='Availed Discount Report' />
			<p className='text-[#fc4343] text-xs ml-5'>
				All Amounts shown here are calculated without considering fines. Please refer to the Fine Wise Report to view
				the fines applied.*
			</p>
			{data?.school?.availed_discounts.length ? (
				<Table
					heading=''
					data={[
						['Student Name', 'Discount Name', 'Value', 'Transaction ID'],
						...data?.school?.availed_discounts.map((data: any, i: any) => [
							<Link to={`/student_profile/${data?.student?._id}`}>
								<div className='items-center flex justify-center'>
									<div className='ml-4'>
										<span className='text-violet-500 text-base'>{data?.student?.name}</span>
									</div>
								</div>
							</Link>,
							data?.discount?.name,
							<div>
								{data?.discount.discount_type === DiscountType.ABSOLUTE_ONE_TIME
									? `₹ ${data?.discount.value.toLocaleString('hi')}`
									: `${data?.discount.value} % of ${
											data?.discount.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD
												? data.discount.fee_head && data.discount?.fee_head?.name
												: data?.discount.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT
												? 'Installment'
												: null
									  }`}
							</div>,
							<div
								className='text-violet-500 cursor-pointer text-base'
								onClick={() => {
									set_modal(true);
									setTransaction({
										student: data?.student,
										invoice: data?.invoice,
										school: data?.school,
									});
								}}
							>
								{data?.invoice?._id}
							</div>,
						]),
					]}
					footer={<Pagination page={page} setPage={setPage} totalPageNo={data?.school?.totalAvailedDiscountPages} />}
				/>
			) : (
				''
			)}

			<Modal open={open_modal} setOpen={set_modal} className='max-w-[50rem] w-full' title={'Transaction Details'}>
				{transaction && (
					<InvoicePDF
						tId={transaction?.invoice?._id}
						invoice_data={transaction?.invoice?.fee_breakup}
						school={transaction?.school}
					/>
				)}
			</Modal>
		</div>
	);
};

export default DiscountReport;
