import React, { useState, useContext, useEffect } from 'react';
import Table from '../../../../components/Table/Table';
import Modal from '../../../../components/Modal/Modal';
import BackButton from '../../../../components/BackButton/BackButton';

import AddEditSectionModal from './AddEditSectionModal';
import editCross from '../../assets/editportalcross.svg';
import { dashboardContext } from '../../Dashboard';
import { Access } from '../../AccessEnum';
import { toast } from 'react-toastify';
import { getSchool } from '../../Dashboard';
import LoadAndRender from '../../../../components/LoadAndRender/LoadAndRender';
const createSection = async (sectionData: any) => {
	const { section } = sectionData;
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({
		class: sectionData.class,
		section: section,
	});

	var requestOptions: RequestInit = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	let res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/section', requestOptions);

	if (res.ok) {
		toast.success('Section Is Created');
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
		return new Error('something wrong!');
	}
};

const AddNewSection = ({ setAddSection, setSectionsList }: any) => {
	const { set_sections } = useContext(dashboardContext);
	const { set_school } = useContext(dashboardContext);
	const { school } = useContext(dashboardContext);
	const { sections } = useContext(dashboardContext);

	const [sectionData, setSectionData]: [any, any] = useState({});
	async function handleAddSection() {
		var res = await createSection(sectionData);
		if (res instanceof Error) {
			toast.warn('Section already present');
		} else {
			setAddSection((prev: any) => !prev);
			setSectionsList((prev: any) => [...prev, res.section]);
			set_sections([...sections, res.section]);
			set_school({ ...school, sections: [...sections, res.section] });
		}
	}
	return (
		<div className='w-full max-w-[20rem] '>
			<AddEditSectionModal setSectionData={setSectionData} section={[]} />
			<div className='mt-2 mb-10 text-center'>
				<button onClick={handleAddSection} className='mt-5 py-2 px-20 rounded-md bg-[#6F6AF8] text-white'>
					{' '}
					Add
				</button>
			</div>
		</div>
	);
};

const Sections = () => {
	const { sections, user, students, set_school, set_sections } = useContext(dashboardContext);
	console.log(sections);

	const classes = new Map();
	sections.map((section: any) => {
		if (!classes.has(section.class)) {
			classes.set(section.class, [{ section: section.section, section_id: section._id }]);
		} else {
			classes.set(section.class, [
				...classes.get(section.class),
				{ section: section.section, section_id: section._id },
			]);
		}
	});

	const [sectionsList, setSectionsList] = useState(
		Array.from(classes, (e) => {
			return { class: e[0], section: e[1] };
		}),
	);
	useEffect(() => {
		setSectionsList(
			Array.from(classes, (e) => {
				return { class: e[0], section: e[1] };
			}),
		);
	}, [sections]);

	const [isAddSection, setAddSection] = useState(false);
	const [show_section, set_show_section] = useState(false);
	const [section, set_section] = useState<any>({ class: '', section: [] });
	const deleteSection = async (id: any) => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');

		var requestOptions: RequestInit = {
			method: 'DELETE',
			headers: myHeaders,
			redirect: 'follow',
		};

		let res: any = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/school/section?id=${id}`, requestOptions);

		if (res.ok) {
			return res;
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
			return res;
		}
	};

	return (
		<div className='mr-5 mb-5'>
			<BackButton label='Sections' />
			{/* <span className='font-bold text-violet-500 text-2xl '>Sections</span> */}
			{user.access === Access.SUPER || user.access === Access.ALL_ACCESS ? (
				<div className='flex justify-end p-1'>
					<button
						onClick={() => setAddSection((prev: any) => !prev)}
						className='py-2 px-6 bg-[#6F6AF8] text-white font-semibold rounded-md'
					>
						Add Section
					</button>
				</div>
			) : (
				''
			)}
			<Modal title='Sections' className='max-w-2xl w-full' open={show_section} setOpen={set_show_section}>
				<Table
					className='hidden '
					data={[
						user.access === Access.SUPER || user.access === Access.ALL_ACCESS
							? ['Section', 'Student Count', 'Action']
							: ['Section', 'Student Count'],

						...section?.section?.map((e: any) => [
							e.section,

							<span className='text-[#6F6AF8]'>
								{
									students?.filter((student: any) => {
										return (
											student.section.toLowerCase() === e.section.toLowerCase() &&
											student.class.toLowerCase() === section.class.toLowerCase()
										);
									}).length
								}
							</span>,
							(user.access === Access.SUPER || user.access === Access.ALL_ACCESS) && (
								<i
									onClick={async () => {
										let res: any = await deleteSection(e.section_id);

										if (!res.ok) {
											toast.warn('section contains students');
										} else {
											toast.success('section is deleted');

											set_section((prev: any) => {
												return {
													class: prev.class,
													section: prev.section.filter((s: any) => {
														return s.section_id !== e.section_id;
													}),
												};
											});
											// setSectionsList((prev: any) => {
											// 	let previous = prev;
											// 	const index = prev.findIndex((e: any) => {
											// 		return e.class === section.class;
											// 	});
											// 	if (test.section <= 0) {
											// 		previous.splice(index, 1);
											// 	} else {
											// 		previous[index] = test;
											// 	}
											// 	return previous;
											// });
											const s = await getSchool();
											set_sections(s.sections);
										}
									}}
									className='fa-solid fa-trash cursor-pointer'
								></i>
							),
						]),
					]}
				></Table>
			</Modal>

			<Table
				heading=''
				className='hidden '
				data={[
					['Class', 'Section', 'Student Count'],
					...sectionsList?.map((e) => [
						e.class,

						<span
							onClick={() => {
								set_show_section(true);
								set_section(e);
							}}
							className='text-[#6F6AF8] cursor-pointer'
						>
							{e.section.length} Sections
						</span>,
						<div>
							{
								students?.filter((student: any) => {
									return student.class.toLowerCase() === e.class.toLowerCase();
								}).length
							}
						</div>,
					]),
				]}
			></Table>

			{isAddSection ? (
				<Modal
					children={<AddNewSection setSectionsList={setSectionsList} setAddSection={setAddSection} />}
					title='Add New Section'
					open={isAddSection}
					className='w-full max-w-sm'
					setOpen={setAddSection}
				/>
			) : (
				''
			)}
		</div>
	);
};
export default Sections;
