import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import LoadingIcon from '../../../../../../assets/loading-purple.svg';

import Input from '../../../../../../components/Input/Input';
import { studentContext } from '../../StudentProfile';
import { get_students } from '../../StudentProfile';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';
import { Access } from '../../../PortalAccess/AccessEnum';
import { dashboardContext } from '../../../../Dashboard';
import Modal from '../../../../../../components/Modal/Modal';
import Form from '../../../../../../components/Form/Form';

const DocumentDetails = ({ setDocumentSelected, getStudent, updateStudent }: any) => {
	const [loader, setLoader] = useState(false);
	const { id } = useParams();
	const { user } = useContext(dashboardContext);

	const [student, setStudent] = useState<any>({});
	const [show_modal, set_show_modal] = useState(false);

	useEffect(() => {
		setDocumentSelected(true);
		return () => {
			setDocumentSelected(false);
		}; // eslint-disable-next-line
	}, []);

	return (
		<LoadAndRender
			promise={async () => {
				setStudent(await getStudent(id));
			}}
			height='none'
		>
			{loader ? (
				<div className='m-auto'>
					<img alt='loader' src={LoadingIcon} />
				</div>
			) : (
				<>
					<Modal open={show_modal} setOpen={set_show_modal}>
						<Form
							onSubmit={async (data: any) => {
								let studentUpdatedData = {
									aadhaar_number: data['Aadhar Number'] || student[0]?.aadhaar_number,
									pan_number: data['Pan Number'] || student[0]?.pan_number,
								};
								setLoader(true);
								await updateStudent(studentUpdatedData, id);
								setLoader(false);
								setStudent(await getStudent(id));
								set_show_modal(false);
							}}
						>
							<Input
								value={student[0]?.aadhaar_number}
								type='number'
								name='Aadhar Number'
								add_error={() => {}}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								maxLength={12}
								required
							/>
							<Input
								value={student[0]?.pan_number}
								type='text'
								name='Pan Number'
								add_error={() => {}}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								maxLength={10}
								required
							/>
							{(user.access === Access.SUPER ||
								user.access === Access.ALL_ACCESS ||
								user.access === Access.MANAGEMENT) && (
								<div className='mt-4 text-right'>
									<button
										type='submit'
										className={`${true ? 'bg-[#6F6AF8]' : 'bg-violet-400'} text-white rounded-lg py-2 px-10 disabled=${
											true ? false : true
										}`}
									>
										Update
									</button>
								</div>
							)}
						</Form>
					</Modal>
					<div className='ml-4 mt-4 mr-2 w-full border-gray-200 rounded-md shadow p-5 mx-5'>
						<div className='flex justify-between items-center mb-2'>
							<h2 className='text-md text-violet-500 text-lg'>Document Details</h2>
							<i
								className='fa-regular fa-edit mr-2 text-[#959595] text-[20px] cursor-pointer'
								onClick={() => set_show_modal(!show_modal)}
							></i>
						</div>

						<div className='grid grid-cols-2 gap-5'>
							<div className='p-2'>
								<div className=' text-base text-[#959595]'>Aadhar Number</div>
								<div className='text-base text-[#1E1B59] capitalize'>{student[0]?.aadhaar_number}</div>
							</div>
							<div className='p-2'>
								<div className=' text-base text-[#959595]'>Pan Number</div>
								<div className='text-base text-[#1E1B59] uppercase'>{student[0]?.pan_number}</div>
							</div>
						</div>
					</div>
				</>
			)}
		</LoadAndRender>
	);
};

export default DocumentDetails;
