import { gql } from '@apollo/client';
export const CREATE_ABSOLUTE_DISCOUNT = gql`
	mutation Create_group_discount(
		$category: [String!]!
		$gender: [String!]!
		$discount_name: String!
		$type: DiscountType!
		$value: Float!
		$class: [String!]!
		$fee_head_id: ID
	) {
		create_group_discount(
			category: $category
			gender: $gender
			discount_name: $discount_name
			type: $type
			value: $value
			class: $class
			fee_head_id: $fee_head_id
		) {
			_id
			name
			value
		}
	}
`;
export const CREATE_SIBLING_DISCOUNT = gql`
	mutation Create_sibling_discount(
		$discount_name: String!
		$type: DiscountType!
		$value: Float!
		$class: [String!]!
		$fee_head_id: ID
	) {
		create_sibling_discount(
			discount_name: $discount_name
			type: $type
			value: $value
			class: $class
			fee_head_id: $fee_head_id
		) {
			_id
			name
			value
		}
	}
`;
