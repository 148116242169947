import React, { useState } from 'react';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import BackButton from '../../../../../../components/BackButton/BackButton';
import Table from '../../../../../../components/Table/Table';
import Select from '../../../../../../components/Select/Select';
import { gql, useQuery } from '@apollo/client';

const query = gql`
	{
		school {
			section_wise_report {
				class
				paid
				section
				total_amount
			}
		}
	}
`;

const SectionWiseReport = () => {
	const { loading, data, error } = useQuery(query);
	return (
		<LoadAndRender loading={loading}>
			<div className='w-full m-5'>
				<BackButton label='Section Wise Report' />
				<p className='text-[#fc4343] text-xs ml-5'>
					All Amounts shown here are calculated without considering fines. Please refer to the Fine Wise Report to view
					the fines applied.*
				</p>
				{!loading && (
					<Table
						data={[
							['Class', 'Section', 'Total Amount', 'Paid', 'Unpaid'],
							...(data?.school?.section_wise_report?.map((e: any) => [
								e.class,
								e.section,
								'₹' + e.total_amount.toLocaleString('hi'),
								'₹' + e.paid.toLocaleString('hi'),
								'₹' + (e.total_amount - e.paid).toLocaleString('hi'),
							]) || []),
						]}
					/>
				)}
			</div>
		</LoadAndRender>
	);
};

export default SectionWiseReport;
