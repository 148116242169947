import React, { useState, useContext } from 'react';
import Form from '../../../../../components/Form/Form';
import Input from '../../../../../components/Input/Input';
import Select from '../../../../../components/Select/Select';
import { dashboardContext } from '../../../Dashboard';
import { studentContext } from '../StudentProfile';
import { preventNegativeValues } from '../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../helper/preventPasteNegative';
import SelectClassSection from '../../../../../components/SelectClassSection/SelectClassSection';

function AddNewStudent1({ createstudent }: any) {
	const [gender, setGender]: [any, any] = useState({ name: 'Male' });
	const [category, setCategory]: [any, any] = useState({ name: 'General' });
	const [sibling, setSibling]: [any, any] = useState({ name: 'Yes' });

	const [class_name, set_class_name] = useState<any>({ name: '1' });

	const [section, setSection] = useState<any>({
		name: 'A',
	});
	const { classes, sections } = useContext(dashboardContext);
	const { setAddStudent } = useContext(studentContext);

	return (
		<Form
			className='grid grid-cols-2 gap-5 '
			onSubmit={async (data: any) => {
				const student = {
					name: data['Name'],
					phone_number: data['Phone No'],
					class: class_name.name,
					section: section.name,
					school_generated_id: data['School generated Id'],
					category: category.name.toLowerCase(),
					dob: data['Date of Birth'],
					father_name: data["Father's Name"],
					gender: gender.name.toLowerCase(),
					sibling: sibling.name,
				};

				const res = await createstudent(student);
				setAddStudent(false);
			}}
		>
			<Input type='text' name='Name' placeholder='Enter Student Name' add_error={() => {}} required />
			<Input type='text' name="Father's Name" placeholder="Enter Father's Name" add_error={() => {}} required />
			<Input
				type='text'
				name='School generated Id'
				placeholder='Enter School generated Id'
				add_error={() => {}}
				required
			/>
			<Select
				selected={gender}
				setSelected={setGender}
				label='Gender'
				options={['male', 'female', 'other'].map((c) => {
					if (c === 'male') {
						return { name: 'Male' };
					}
					if (c === 'female') {
						return { name: 'Female' };
					}
					if (c === 'other') {
						return { name: 'Other' };
					}
				})}
			/>

			<Select
				selected={class_name}
				setSelected={(c: any) => {
					set_class_name(c);
					setSection(
						sections
							.filter((section: any) => section.class === c.name)
							.map((s: any) => {
								return { ...section, name: s.section };
							})[0],
					);
				}}
				label='Class'
				options={classes.map((c: any) => {
					return { name: c };
				})}
			/>
			{section && (
				<Select
					selected={section}
					setSelected={setSection}
					label='Section'
					options={sections
						.filter((section: any) => section.class === class_name.name)
						.map((c: any) => {
							return { ...section, name: c.section };
						})}
				/>
			)}
			<Select
				selected={category}
				setSelected={setCategory}
				options={['general', 'sc', 'st', 'obc'].map((c) => {
					return { name: c === 'general' ? 'General' : c.toUpperCase() };
				})}
				label='Category'
			/>
			<Select
				selected={sibling}
				setSelected={setSibling}
				label='Sibling'
				options={['Yes', 'No'].map((c) => {
					return { name: c.toUpperCase() };
				})}
			/>
			<Input
				name='Phone No'
				add_error={() => {}}
				type='number'
				placeholder='Enter Phone no'
				onKeyDown={preventNegativeValues}
				onPaste={preventPasteNegative}
				min={0}
				maxLength={10}
				required
			/>
			<Input
				type='date'
				name='Date of Birth'
				min='1990-01-01'
				max={`${new Date().getFullYear()}-${
					new Date().getMonth() > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)
				}-${new Date().getDate() > 9 ? new Date().getDate() + 1 : '0' + new Date().getDate()}`}
				required
			/>
			<div className='mt-2 col-span-2  mb-10 text-center'>
				<button className='py-2 px-16 max-w-[15rem] w-full rounded-lg bg-[#6F6AF8] text-white'>Add</button>
			</div>
		</Form>
	);
}

export default AddNewStudent1;
