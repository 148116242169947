import React from 'react';

import { Route, Routes } from 'react-router-dom';

import CollectOverview from '../CollectOverview/CollectOverview';

const CollectFee = ({ _loading }: any) => {
	return (
		<div className='fee-config w-full flex flex-col my-5 px-5'>
			<div className='menu'></div>
			<div className=''>
				<Routes>
					<Route path='/' element={<CollectOverview _loading={_loading} />} />
				</Routes>
			</div>
		</div>
	);
};

export default CollectFee;
