import React, { useEffect, useState } from 'react';
import CheckboxInput from '../../../../../../../components/CheckboxInput/CheckboxInput';
import RadioInput from '../../../../../../../components/RadioInput/RadioInput';
import Input from '../../../../../../../components/Input/Input';
import Form from '../../../../../../../components/Form/Form';
import Select from '../../../../../../../components/Select/Select';
import { DiscountType } from './CreateGroupDiscount';
function EditStudentDiscount({
	single_discount,
	updateDiscount,
	getDiscounts,
	set_get_discounts,
	set_show_modal,
}: any) {
	const [fee_type, set_fee_type] = React.useState('');
	const [discount_name, set_discount_name] = useState<any>('');
	const [discount_value, set_discount_value] = useState<any>('');
	const [isFeehead, setIsFeeHead] = React.useState<any>(false);
	const [fee_heads, set_fee_heads] = React.useState<any>([]);
	const [fee_head, set_fee_head] = React.useState<any>({});
	const [discount_type, set_discount_type] = React.useState<any>(
		single_discount?.discount_type === 'ABSOLUTE_ONE_TIME' ? 'Absolute One Time' : 'Recurring Percentage',
	);

	useEffect(() => {
		set_discount_type(
			single_discount?.discount_type === 'ABSOLUTE_ONE_TIME' ? 'Absolute One Time' : 'Recurring Percentage',
		);
	}, [single_discount?.discount_type]);
	const getFeeHeadForGroup = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(
			process.env.REACT_APP_BACKEND_URL + `/api/fee-config/fee-head-group?class=${single_discount?.student?.class}`,
			requestOptions,
		);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	React.useEffect(() => {
		(async () => {
			if (isFeehead === true) {
				if (single_discount?.student.class) {
					set_fee_heads(await getFeeHeadForGroup());
				}
			}
		})();
	}, [isFeehead]);
	console.log(!(isFeehead === true && fee_head.length <= 0));

	return (
		<Form
			className='w-full grid grid-cols-2 gap-x-5'
			onSubmit={async (data: any) => {
				let formData;
				if (discount_type === 'Absolute One Time') {
					formData = {
						name: discount_name || single_discount?.name,
						value: discount_value || single_discount?.value,
						discount_type: DiscountType.ABSOLUTE_ONE_TIME,
					};
				}
				if (discount_type === 'Recurring Percentage') {
					if (isFeehead === true) {
						formData = {
							name: discount_name || single_discount?.name,
							value: discount_value || single_discount?.value,
							discount_type: DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD,
							fee_head: fee_head[0]?._id,
						};
					} else {
						formData = {
							name: discount_name || single_discount?.name,
							value: discount_value || single_discount?.value,
							discount_type: DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT,
						};
					}
				}
				const res = await updateDiscount(formData);
				if (res instanceof Error) {
					console.log('something wrong');
				} else {
					set_get_discounts(await getDiscounts());
					set_show_modal(false);
				}
			}}
		>
			<div className='w-full flex col-span-2 justify-center items-center'>
				<RadioInput
					onChange={(n: any, v: any) => {
						set_discount_type(v);
						set_discount_name('');
						set_discount_value('');
					}}
					color='text-violet-500'
					add_error={() => {}}
					value={single_discount?.discount_type === 'ABSOLUTE_ONE_TIME' ? 'Absolute One Time' : 'Recurring Percentage'}
					options={['Absolute One Time', 'Recurring Percentage']}
					required
				/>
			</div>
			{discount_type === 'Absolute One Time' ? (
				<>
					<Input
						add_error={() => {}}
						name='Discount Name'
						value={single_discount.name}
						type='text'
						placeholder='Enter Name'
						required
						onChange={(e: any, v: any) => {
							set_discount_name(v);
						}}
					/>
					<Input
						name='Value'
						type='number'
						add_error={() => {}}
						prefix={discount_type === 'Absolute One Time' ? '₹' : '%'}
						value={single_discount.value}
						onChange={(e: any, v: any) => {
							set_discount_value(v);
						}}
						placeholder='Enter Value'
						required
					/>
				</>
			) : (
				<>
					<Input
						add_error={() => {}}
						name='Discount Name'
						value={single_discount.name}
						type='text'
						placeholder='Enter Name'
						required
						onChange={(e: any, v: any) => {
							set_discount_name(v);
						}}
					/>
					<Input
						name='Value'
						type='number'
						add_error={() => {}}
						prefix={discount_type === 'Absolute One Time' ? '₹' : '%'}
						value={single_discount.value}
						onChange={(e: any, v: any) => {
							set_discount_value(v);
						}}
						placeholder='Enter Value'
						maxAmount={100}
						required
					/>
					<RadioInput
						name='Discount'
						onChange={(n: any, v: any) => {
							if (v === 'Feehead') {
								setIsFeeHead(true);
								set_fee_type(v);
							} else {
								setIsFeeHead(false);
								set_fee_type(v);
							}
						}}
						add_error={() => {}}
						options={['Feehead', 'Installment']}
						required
					/>
					{isFeehead === true ? (
						<>
							{fee_heads.length > 0 ? (
								<RadioInput
									name='Select Fee Head'
									onChange={(e: any, v: any) => {
										set_fee_head(
											Object.values(fee_heads).filter((fee: any) => {
												return fee.name === v;
											}),
										);
									}}
									add_error={() => {}}
									options={Object.values(fee_heads).map((fee: any) => {
										return fee.name;
									})}
									required
								/>
							) : (
								<div></div>
							)}
						</>
					) : (
						<div></div>
					)}
				</>
			)}

			<div className='w-full flex mt-2 items-center col-span-2'>
				<button
					disabled={
						(discount_type === 'Absolute One Time' && !(discount_name.length && discount_value.length)) ||
						(isFeehead === true && fee_head.length <= 0)
					}
					className='w-full mx-auto inline-flex justify-center rounded-md disabled:bg-indigo-300 bg-[#6F6AF8] px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]  sm:w-auto'
				>
					Update Discount
				</button>
			</div>
		</Form>
	);
}

export default EditStudentDiscount;
