import React, { useState } from 'react';
import payment_failed from '../../../../../src/assets/payment_failed.svg';
import { useNavigate, useParams } from 'react-router-dom';
import LoadAndRender from '../../../../components/LoadAndRender/LoadAndRender';
import { get_invoice_data } from '../FeeCollection/pages/FeeOverview/FeeOverview';

function PaymentFailure() {
	const navigate = useNavigate();
	let { id } = useParams();
	const [data, setData] = useState<any>({});
	const [open_modal, set_modal] = useState(false);
	const [selected_transaction, set_selected_transaction] = useState<any>(null);
	const [fee_breakup, set_fee_breakup] = useState<any>(null);
	const [fee_heads, set_fee_heads] = useState<any>(null);
	const getInvoice = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};
		return await (await fetch(process.env.REACT_APP_BACKEND_URL + `/payment/invoice/${id}`, requestOptions)).json();
	};

	return (
		<LoadAndRender
			className=' h-full w-full'
			promise={async () => {
				const res = await get_invoice_data(id);
				setData(res[0]);
			}}
		>
			<div className='flex w-full h-full justify-between items-center p-4'>
				<div className='max-w-3xl w-full mx-auto rounded-lg shadow border px-4 py-8'>
					<div className='w-full flex justify-center items-center my-4'>
						<img src={payment_failed} alt={payment_failed} />
					</div>

					<h3 className='text-[#1E1B59] text-3xl font-medium text-center'>
						Oh no! <br /> Payment Failed
					</h3>

					<p className='text-center  text-[#1E1B59] text-md font-medium'>Order ID: {id}</p>
					<p className='text-center mt-4 mb-8 text-[#1E1B59] text-md font-medium'>
						Amount: ₹{data?.fee_total && data?.fee_total.toLocaleString('hi')}
					</p>

					<p className='text-xl text-center font-medium text-[#959595] my-2'>
						If any money was debited from your account, it will be credited back in 7 business days. Sorry for the
						inconvenience caused.
					</p>
					<div className='w-full flex justify-center items-center'>
						<button
							className='px-4 py-2 border bg-red-600 text-white rounded-lg max-w-xs mt-2 w-full mx-auto'
							onClick={() => navigate(-3)}
						>
							Retry Payment
						</button>
					</div>
				</div>
			</div>
		</LoadAndRender>
	);
}

export default PaymentFailure;
