import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../../../../../components/Input/Input';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Modal from '../../../../../../components/Modal/Modal';
import { dashboardContext } from '../../../../Dashboard';
import { Access } from '../../../PortalAccess/AccessEnum';
import LoadingIcon from '../../../../../../assets/loading-purple.svg';
import Form from '../../../../../../components/Form/Form';
import Select from '../../../../../../components/Select/Select';

function MedicalDetails({ setMedicalDetialsSelected, getStudent, updateStudent }: any) {
	const [loader, setLoader] = useState(false);
	const { id } = useParams();
	const { user } = useContext(dashboardContext);
	const [student, setStudent] = useState<any>({});
	const [show_modal, set_show_modal] = useState(false);
	const [allergies, set_Allergies] = useState<any>({
		name: student[0]?.allergies === String(1) ? 'Yes' : 'No',
	});
	const [person_with_disability, set_person_with_disability] = useState<any>({
		name: student[0]?.person_with_disability === String(1) ? 'Yes' : 'No',
	});

	useEffect(() => {
		setMedicalDetialsSelected(true);
		return () => {
			setMedicalDetialsSelected(false);
		}; // eslint-disable-next-line
	}, []);
	// console.log(student[0]?.allergies === String(1) ? 'Yes' : 'No');

	return (
		<LoadAndRender
			promise={async () => {
				setStudent(await getStudent(id));
			}}
			height='none'
		>
			{loader ? (
				<div className='m-auto'>
					<img src={LoadingIcon} alt='Loading...' />
				</div>
			) : (
				<div className='ml-4 mt-4 mr-2 w-full border-gray-200 rounded-md shadow p-5 mx-5'>
					<Modal open={show_modal} setOpen={set_show_modal}>
						<Form
							onSubmit={async (data: any) => {
								let studentUpdatedData = {
									weight: data['Weight'] || student[0]?.weight,
									height: data['Height'] || student[0]?.height,
									allergies: allergies?.name === 'Yes' ? 1 : 0,
									person_with_disability: person_with_disability?.name === 'Yes' ? 1 : 0,
									type_of_disability: data['Type Of Disability'] || student[0]?.type_of_disability,
									identification_mark: data['Identification Mark'] || student[0]?.identification_mark,
								};
								setLoader(true);
								await updateStudent(studentUpdatedData, id);
								setStudent(await getStudent(id));
								setLoader(false);
								set_show_modal(false);
							}}
						>
							<Input postfix='KG' value={student[0]?.weight} type='number' name='Weight' add_error={() => {}} />
							<Input postfix='INCH' value={student[0]?.height} type='number' name='Height' add_error={() => {}} />
							<Select
								label='Allergies'
								options={['Yes', 'No'].map((c) => {
									return { name: c };
								})}
								selected={allergies}
								setSelected={set_Allergies}
							/>
							<Select
								label='Person With Disability'
								options={['Yes', 'No'].map((c) => {
									return { name: c };
								})}
								selected={person_with_disability}
								setSelected={set_person_with_disability}
							/>

							<Input
								value={student[0]?.type_of_disability}
								type='text'
								name='Type Of Disability'
								add_error={() => {}}
							/>
							<Input
								value={student[0]?.identification_mark}
								type='text'
								name='Identification Mark'
								add_error={() => {}}
							/>

							{(user.access === Access.SUPER ||
								user.access === Access.ALL_ACCESS ||
								user.access === Access.MANAGEMENT) && (
								<div className='mt-4 text-right'>
									<button
										className={` bg-[#6F6AF8]
							 text-white rounded-lg py-2 px-10 `}
									>
										Update
									</button>
								</div>
							)}
						</Form>
					</Modal>
					<div className='flex justify-between items-center mb-2'>
						<h2 className='text-md text-violet-500 text-lg'>Medical Details</h2>
						<i
							className='fa-regular fa-edit mr-2 text-[#959595] text-[20px] cursor-pointer'
							onClick={() => set_show_modal(!show_modal)}
						></i>
					</div>
					<div className='grid grid-cols-2 gap-5'>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Weight</div>
							<div className='text-base text-[#1E1B59] capitalize'>
								{student[0]?.weight ? `${student[0]?.weight} KG` : 'NA'}
							</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Height</div>
							<div className='text-base text-[#1E1B59] capitalize'>
								{student[0]?.height ? `${student[0]?.height} "` : 'NA'}
							</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Allergies</div>
							<div className='text-base text-[#1E1B59] capitalize'>
								{student[0]?.allergies === String(1) ? 'Yes' : 'No' || 'NA'}
							</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Person With Disability</div>
							<div className='text-base text-[#1E1B59]'>
								{student[0]?.person_with_disability === String(1) ? 'Yes' : 'No' || 'NA'}
							</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Type Of Disability</div>
							<div className='text-base text-[#1E1B59] uppercase'>{student[0]?.type_of_disability || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Identification Mark</div>
							<div className='text-base text-[#1E1B59] uppercase'>{student[0]?.identification_mark || 'NA'}</div>
						</div>
					</div>
				</div>
			)}
		</LoadAndRender>
	);
}

export default MedicalDetails;
