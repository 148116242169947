import React from 'react';

function SequentialInstallmentSelect({
	due_dates,
	paid_till_installment_index,
	pay_till_installment_index,
	set_pay_till_installment_index,
	name,
	installments,
}: {
	due_dates: Date[];
	paid_till_installment_index: number;
	pay_till_installment_index: number;
	name: string;
	installments: [];
	set_pay_till_installment_index: (index: number) => void;
}) {
	const [open_menu, set_open_menu] = React.useState(false);
	console.log(installments.filter((d: any) => d.to_pay > 0).length);

	return (
		<>
			<label className='block text-sm font-medium leading-6 text-start text-gray-900 my-2'>
				{'Select ' + name || ''}
			</label>
			<div
				onClick={() => set_open_menu(!open_menu)}
				className='relative flex justify-between items-center cursor-pointer min-w-[20rem] max-w-xs w-full rounded-md py-1.5 px-3 text-left text-gray-900 shadow ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 '
			>
				{installments.filter((d: any) => d.to_pay > 0).length > 0 ? (
					installments.filter((d: any) => d.to_pay > 0).length + ' Selected'
				) : (
					<span>{'Select ' + name || ''}</span>
				)}
				<i
					className={
						'fa-solid fa-chevron-up transform transition-all duration-300 ' +
						(open_menu ? '  rotate-180' : ' -rotate-0')
					}
				></i>
			</div>
			{open_menu && (
				<div className='checkbox-input text-start my-2 text-sm font-light'>
					<div className='grid grid-cols-1 max-h-60 h-full  transition-all duration-300 max-w-xs w-full z-10 mt-1 overflow-auto rounded-lg bg-[#F8FAFB] p-4 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm '>
						<label
							key={-1}
							className='text-gray-900 flex gap-2 items-center truncate rounded-lg relative hover:bg-violet-500 hover:text-white cursor-pointer py-2 pl-3 pr-9'
						>
							<input
								className='accent-violet-500'
								checked={pay_till_installment_index === due_dates?.length - 1}
								onChange={(e) => {
									if (!e.target.checked) {
										set_pay_till_installment_index(paid_till_installment_index);
									} else {
										due_dates.slice(0, due_dates?.length - 1);
										set_pay_till_installment_index(due_dates?.length - 1);
									}
								}}
								type='checkbox'
							/>
							Select All
						</label>
						{due_dates.map((date: Date, index) => {
							return (
								<label
									key={index}
									className='text-gray-900 flex gap-2 items-center truncate rounded-lg relative hover:bg-violet-500 hover:text-white cursor-pointer py-2 pl-3 pr-9'
								>
									<input
										className='accent-violet-500'
										onChange={(e) => {
											if (e.target.checked) {
												set_pay_till_installment_index(index);
											} else {
												set_pay_till_installment_index(index - 1);
											}
										}}
										checked={index <= pay_till_installment_index}
										disabled={index <= paid_till_installment_index || index > pay_till_installment_index + 1}
										type='checkbox'
									/>{' '}
									<span className='truncate'>
										Installment {index + 1} <span className='text-sm '>(due on {date.toDateString()})</span>
									</span>
								</label>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
}

export default SequentialInstallmentSelect;
