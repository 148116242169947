import React, { useContext, useState } from 'react';
import Table from '../../../../../../components/Table/Table';
import { Link } from 'react-router-dom';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Pagination from '../../../../../../components/Pagination/Pagination';
import Modal from '../../../../../../components/Modal/Modal';
import { DownloadPDF } from '../FeeOverview/FeeOverview';
import { get_invoice_data } from '../FeeOverview/FeeOverview';
import Loading from '../../../../Loading';
import RecentTransactionSmallLoader from '../../../SmallSkeleton/RecentTransactionSmallLoader';
import InvoicePDF from '../FeeOverview/InvoicePDF';
import { dashboardContext } from '../../../../Dashboard';
const FeeTransactions = () => {
	const [page, setPage] = useState(1);
	const [totalPageNo, setTotalPageNo] = useState(null);
	const [transaction, setTransaction] = useState<any>(null);
	const [getStudents, setGetStudents] = useState<any>(null);
	const [open_modal, set_modal] = useState(false);
	const transactionPerPage = transaction?.length;
	const [transaction_id, set_transaction_id] = useState<any>(null);
	const [invoice_data, set_invoice_data] = useState<any>([]);
	const [fee_breakup, set_fee_breakup] = useState<any>(null);
	const { school } = useContext(dashboardContext);
	async function handleInvoice(transaction_id: any) {
		const res = await get_invoice_data(transaction_id);
		if (res) {
			set_invoice_data(res);
		}
		set_fee_breakup(JSON.parse(res[0].fee_breakup));
	}
	const recentTransaction = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/school/transactions?page=${page}`,
			requestOptions,
		);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	return (
		<>
			<LoadAndRender
				reload_upon_change={[page]}
				promise={async () => {
					const res = await recentTransaction();
					setTransaction(res?.transactions);
					setTotalPageNo(res?.total_pages);
				}}
			>
				{transaction?.length > 0 && (
					<Table
						heading={'Transactions'}
						csv_file_name='Recent Transactions'
						data={[
							['Student Name', 'Father Name', 'Class & Section', 'Transaction ID', 'Amount', 'Payment Mode', 'Time'],
							...transaction.map((r: any) => [
								<Link to={'/student_profile/' + r?.student?._id} className='text-violet-500'>
									{r?.student?.name}
								</Link>,
								r.student?.father_name,
								<span>{`${r.student?.class ? r.student?.class : ''} - ${
									r?.student?.section ? r?.student?.section : ''
								}`}</span>,

								<div
									className='w-full truncate cursor-pointer text-violet-500'
									onClick={() => {
										set_modal(true);
										handleInvoice(r?._id);
										set_transaction_id(r?._id);
										set_invoice_data([]);
									}}
								>
									{`${r?._id}`}
								</div>,
								<h2 className='text-[#2EB418]'>{'₹ ' + r?.fee_total?.toLocaleString('hi')}</h2>,
								r?.payment_mode,
								new Date(r.updatedAt).toDateString(),
								'',
							]),
						]}
						footer={totalPageNo && <Pagination page={page} setPage={setPage} totalPageNo={totalPageNo} />}
					/>
				)}
				<Modal open={open_modal} setOpen={set_modal} className='max-w-[50rem] w-full' title={'Transaction Details'}>
					{invoice_data[0] && (
						<InvoicePDF tId={transaction_id} invoice_data={invoice_data[0]} school={school?.school} />
					)}
				</Modal>
			</LoadAndRender>
		</>
	);
};

export default FeeTransactions;
