import React, { useContext } from 'react';
import editIcon from '../../../assets/editiconschool.svg';
import schoolIcon from '../../../assets/schoollogo.svg';
import { Link } from 'react-router-dom';
import { dashboardContext } from '../../../Dashboard';
import { Access } from '../AccessEnum';

const SchoolDetails = ({}: any) => {
	// console.log("schools", schools);
	const { classes } = useContext(dashboardContext);
	const { sections, school, user } = useContext(dashboardContext);
	const schools = school.school;
	const fee_collection_date_sup =
		schools &&
		(schools.fee_collection_date === 1
			? 'st'
			: schools.fee_collection_date === 2
			? 'nd'
			: schools.fee_collection_date === 3
			? 'rd'
			: 'th');
	console.log(schools);

	return (
		<>
			<div className='text-2xl  text-violet-600'>School Details</div>
			<div className='my-3 h-[1px] w-full bg-[#6F6AF8]'></div>
			<div className=' px-8  bg-[#F8FAFB] font-semibold'>
				<div>
					<div className='flex items-center'>
						<div className='w-28 h-28 flex-none flex justify-center items-center overflow-hidden rounded-full bg-gray-200'>
							{schools.logo_url ? (
								<img src={schools.logo_url} alt='' className='w-full h-full object-contain' />
							) : (
								<i className='fa-solid fa-school-flag text-5xl'></i>
							)}
						</div>

						<div className='px-10'>
							<div className='text-2xl text-violet-600 font-semibold'>{schools.name}</div>
							{/* <div className='py-2 text-gray-400 font-normal text-md'>Description</div> */}
							<div className='py-2 grid grid-cols-4 gap-4 text-md text-[#1E1B59]'>
								<div>{classes.length} Classes</div>
								<div>{sections.length} Sections</div>
								{/* <div>100 Teachers</div> */}
								<div>{schools.student_count} Students</div>
							</div>
						</div>
					</div>
					<div className='w-4/5'>
						<div className='grid grid-cols-3'>
							<div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Type of School</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.school_type}</div>
							</div>
							<div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Date of Registration</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{new Date(schools?.createdAt).toDateString()}</div>
							</div>
							<div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Edviron Id</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.edviron_id}</div>
							</div>
							{/* <div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>LEAD Registration NUmber</div>
								<div className='mt-4 text-md text-[#1E1B59] '>AA-100</div>
							</div> */}
							<div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Address</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.address && schools.address.street}</div>
							</div>
							<div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>City</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.address && schools.address.city}</div>
							</div>
							<div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>State</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.address && schools.address.state}</div>
							</div>
							<div className=' mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Pin Code</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.address && schools.address.pin}</div>
							</div>
							<div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Fee Collection Date</div>
								<div className='mt-4 text-md text-[#1E1B59] '>
									{schools.fee_collection_date}
									<sup>{fee_collection_date_sup}</sup> (of every month)
								</div>
							</div>
							{/* <div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Late Fee</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.late_fee}</div>
							</div>
							<div className='mt-10 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Late Fee Grace Period</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.late_fee_grace_period}</div>
							</div> */}
						</div>
					</div>
					<div className='mt-10 w-4/5'>
						<div className='text-2xl  text-violet-600'>Primary Contact</div>
						<div className='grid grid-cols-3'>
							{/* <div className='mt-4 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Principal Name</div>
								<div className='mt-4 text-md text-[#1E1B59] '>xyz</div>
							</div> */}
							<div className=' mt-4 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Contact Number</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.phone_number}</div>
							</div>
							<div className='mt-4 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Email ID</div>
								<div className='mt-4 text-md text-[#1E1B59] '>{schools.email_id}</div>
							</div>
						</div>
					</div>
					<div className='mt-10 w-4/5'>
						<div className='text-2xl  text-violet-600'>Bank Details</div>
						<div className='grid grid-cols-3'>
							<div className='mt-4 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Account Holder Name</div>
								<div className='mt-4 text-md text-[#1E1B59] '>
									{schools.bank_details && schools.bank_details.account_holder_name}
								</div>
							</div>
							<div className=' mt-4 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Account Number</div>
								<div className='mt-4 text-md text-[#1E1B59] '>
									{schools.bank_details && schools.bank_details.account_number}
								</div>
							</div>
							<div className='mt-4 col-span-2  w-full object-contain lg:col-span-1 '>
								<div className='text-gray-400 font-normal'>Ifsc Code</div>
								<div className='mt-4 text-md text-[#1E1B59] '>
									{schools.bank_details && schools.bank_details.account_ifse_code}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SchoolDetails;
