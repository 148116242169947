import React, { createContext } from 'react';
import { Link } from 'react-router-dom';
export const menuContext = createContext<any>('');
export const Menu = ({ children, path, set_path }: any) => {
	// const [path, set_path] = React.useState<any>(window.location.pathname);

	return (
		<div className='menu w-full'>
			<div className='rounded-md bg-[#dadbfc]  px-5 flex gap-12'>
				{children.map((child: any, i: number) => React.cloneElement(child, { key: i, path, set_path }))}
			</div>
		</div>
	);
};

export const MenuItem = ({ name, path, set_path, to }: any) => {
	const active = path === to;

	return (
		<Link to={to} onClick={() => set_path(to)}>
			<div className={'menu-item cursor-pointer font-medium text'}>
				<div className='menu-item-name pt-2 pb-1 mx-2'>{name}</div>
				{active && <div className='rounded-t h-1 bg-[#6F6AF8] w-full'></div>}
			</div>
		</Link>
	);
};
