import React, { useContext } from 'react';
import Modal from '../../../../../../../components/Modal/Modal';
import { deletDiscount } from '../DiscountsTab';
import userEvent from '@testing-library/user-event';
import { Access } from '../../../../PortalAccess/AccessEnum';
import { dashboardContext } from '../../../../../Dashboard';
import { DiscountType } from './CreateGroupDiscount';

const DeleteFeeHead = ({ setDeleteFeeHead, id, set_get_discounts, getDiscounts }: any) => {
	async function handleDelete() {
		try {
			let res = await deletDiscount(id);
			if (res instanceof Error) {
				console.log('something went wrong!');
			} else {
				setDeleteFeeHead(false);
				set_get_discounts(await getDiscounts());
			}
		} catch (err) {
			console.log(err);
		}
	}
	return (
		<>
			<div className='w-11/12 m-auto text-center'>
				<div className='mt-10'>Are you sure you want to delete this discount?</div>
				<div className='mt-10 mb-10 grid grid-cols-2 gap-4'>
					<button
						onClick={() => setDeleteFeeHead(false)}
						className='py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none'
					>
						Cancel
					</button>
					<button onClick={handleDelete} className='py-2 px-4 rounded-md bg-red-400 text-white outline-none'>
						Delete
					</button>
				</div>
			</div>
		</>
	);
};

const EditDelete = ({ setToggle, setDeleteFeeHead }: any) => {
	return (
		<div className='absolute py-2  bottom-0 right-5 hover:bg-slate-200 px-4 w-36 rounded-lg shadow bg-[#F8FAFB]'>
			{/* <div
				onClick={() => {
					setEditFeeHead(true);
					setToggle(false);
				}}
				className='py-2 cursor-pointer'
			>
				Edit
			</div> */}
			{/* <hr /> */}
			<div
				onClick={() => {
					setDeleteFeeHead(true);
					setToggle(false);
				}}
				className='text-red-400 cursor-pointer'
			>
				Delete
			</div>
		</div>
	);
};
function GroupDiscountCard({ discount, set_get_discounts, getDiscounts, get_fee_heads }: any) {
	const [showModal, setShowModal] = React.useState(false);
	const [showFeeHeadModal, setShowFeeHeadModal] = React.useState(false);
	const [toggle, setToggle] = React.useState(false);
	const [isDeleteFeeHead, setDeleteFeeHead] = React.useState(false);
	const { user } = useContext(dashboardContext);
	return (
		<div
			onMouseLeave={() => {
				if (toggle === true) {
					setToggle(false);
				}
			}}
		>
			<div className='fee-head-card h-full'>
				<Modal open={showModal} setOpen={setShowModal} title='Classes'>
					{
						<div className='mx-auto text-center'>
							{discount.group_class.map((class_name: any) => (
								<div key={class_name}>{class_name}</div>
							))}
						</div>
					}
				</Modal>
				<Modal open={showFeeHeadModal} setOpen={setShowFeeHeadModal} title='Fee Head' className='max-w-2xl w-full'>
					{get_fee_heads
						.filter((f: any) => f._id === discount?.fee_head)
						.map((fee_head: any) => {
							return (
								<div key={fee_head._id} className='p-5 shadow rounded-md h-full'>
									<div className='fee_head_header font-medium flex justify-between items-center'>
										<div> {fee_head.name}</div>
									</div>
									<div className='grid grid-cols-3 mt-4 gap-4'>
										<div className=''>
											<div className='text-sm font-light '>Fee</div>
											<div className='mt-2 text-sm font-medium'>
												{'₹' + (fee_head.amount as number).toLocaleString('hi')}
											</div>
										</div>
										<div className='border-l-2 px-4'>
											<div className='text-sm font-light '>Tax</div>
											<div className='mt-2 text-sm font-medium'>{fee_head.tax + '%'}</div>
										</div>
										<div className='border-l-2 px-4'>
											<div className='text-sm font-light '>Class</div>
											<div className='mt-2 text-sm font-medium '>{fee_head.class.join(',')}</div>
										</div>
										<div className=''>
											<div className='text-sm font-light '>Starting Date</div>
											<div className='mt-2 text-sm font-medium'>{new Date(fee_head.start_date).toDateString()}</div>
										</div>
										<div className='border-l-2 px-4'>
											<div className='text-sm font-light '>Total Fee</div>
											<div className='mt-2 text-sm font-medium'>
												₹{((fee_head.amount * 12) / fee_head.frequency_months).toLocaleString('hi')}
											</div>
										</div>
									</div>
								</div>
							);
						})}
				</Modal>
				<Modal
					children={
						<DeleteFeeHead
							setDeleteFeeHead={setDeleteFeeHead}
							id={discount._id}
							set_get_discounts={set_get_discounts}
							getDiscounts={getDiscounts}
						/>
					}
					title='Delete'
					open={isDeleteFeeHead}
					setOpen={setDeleteFeeHead}
				/>
				<div className='p-5 shadow rounded-md h-full'>
					<div className='fee_head_header font-medium flex justify-between items-center'>
						<div>{discount.name}</div>
						<div className='relative'>
							{(user.access === Access.SUPER || user.access === Access.ALL_ACCESS) && (
								<i
									onClick={() => setToggle((prev) => !prev)}
									className='fa-solid fa-ellipsis-vertical ml-auto cursor-pointer p-2'
								></i>
							)}
							{toggle ? <EditDelete setDeleteFeeHead={setDeleteFeeHead} setToggle={setToggle} /> : ''}
						</div>
					</div>
					<div className='grid  grid-cols-2 mt-4 gap-4'>
						{/* <div className=''>
							<div className='text-sm font-light '>Type</div>
							<div className='mt-2 text-sm font-medium'>{discount.type}</div>
						</div> */}

						<div className=''>
							<div className='text-sm font-light '>Value</div>
							<div>
								{discount.discount_type === DiscountType.ABSOLUTE_ONE_TIME ? (
									`₹ ${discount.value.toLocaleString('hi')}`
								) : (
									<div>
										<span className={'text-black text-sm font-medium capitalize'}>{discount.value}% of</span>
										<span
											onClick={() => {
												if (discount?.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD) {
													setShowFeeHeadModal(!showFeeHeadModal);
												}
											}}
											className={
												'ml-2 text-sm font-medium capitalize ' +
												(discount?.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD
													? '  text-violet-600 cursor-pointer'
													: '')
											}
										>
											{discount?.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD
												? get_fee_heads.filter((f: any) => f._id === discount?.fee_head)[0]?.name
												: discount?.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT
												? 'Installment'
												: null}
										</span>
									</div>
								)}
							</div>
						</div>

						<div className='border-l-2 px-4'>
							<div className='text-sm font-light '>Class</div>
							<div
								className='mt-2 text-sm font-medium text-violet-600 cursor-pointer'
								onClick={() => {
									setShowModal(true);
								}}
							>
								{discount.group_class.length + ' Classes'}
							</div>
						</div>
						<div className='pr-4'>
							<div className='text-sm font-light '>Categories</div>
							<div className={'mt-2 text-sm font-medium '}>
								{discount.group_category
									.map((e: any) => {
										if (e === 'general') return 'General';
										if (e === 'sc') return 'SC';
										if (e === 'st') return 'ST';
										if (e === 'obc') return 'OBC';
										else return e;
									})
									.join(', ')}
							</div>
						</div>

						<div className=' border-l-2 px-4'>
							<div className='text-sm font-light '>Gender</div>
							<div className='mt-2 text-sm font-medium capitalize'>{discount.group_gender.join(', ')}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GroupDiscountCard;
