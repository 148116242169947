/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import FeeHeadCard from '../.././components/FeeHeadCard/FeeHeadCard';
import Modal from '../../../../../../components/Modal/Modal';
import Input from '../../../../../../components/Input/Input';
import Form from '../../../../../../components/Form/Form';
import CheckboxInput from '../../../../../../components/CheckboxInput/CheckboxInput';
import RadioInput from '../../../../../../components/RadioInput/RadioInput';
// import Button from "../../../../../../components/Button/Button";
import Switch from '../../../../../../components/Switch/Switch';
import Select from '../../../../../../components/Select/Select';
import Table from '../../../../../../components/Table/Table';
import React, { useContext, useEffect, useState } from 'react';
import { dashboardContext } from '../../../../Dashboard';
import { Access } from '../../../PortalAccess/AccessEnum';
import Loading from '../../../../Loading';
import FeeConfigSmallLoader from '../../../SmallSkeleton/FeeConfigSmallLoader';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import FeeStructureModal from './FeeStructureModal';

import { DownloadPDF } from '../../../FeeCollection/pages/FeeOverview/FeeOverview';

export const get_fee_heads = async () => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/fee_heads', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

// const get_classes = async () => {
// 	const {classes} = useContext(dashboardContext);
// 	return classes;
// };

export const fee_type_string_to_frequency_months = (fee_type: string) => {
	switch (fee_type) {
		case 'Monthly':
			return 1;
		case 'Bimonthly':
			return 2;
		case 'Quarterly':
			return 3;
		case 'Half Yearly':
			return 6;
		case 'One Time':
			return 12;
		default:
			return 1;
	}
};

export const boolean_map_to_array = (map: any) => {
	const arr: any = [];
	for (const key in map) {
		if (map[key]) {
			arr.push(key);
		}
	}
	return arr.map((v: any) => {
		return v;
	});
};
const create_fee_head = async (data: any) => {
	const { name, fm, amt, classes, genders, categories, tax, start_date } = data;
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({
		name: name,
		frequency_months: fm,
		amount: amt,
		class: classes,
		gender: genders,
		category: categories,
		tax: tax,
		start_date: start_date,
	});

	var requestOptions: RequestInit = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/fee-config/create-fee-head', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const sum_installment = (applicable_fee_heads: any[]) => {
	return applicable_fee_heads
		.map((fee_head) => fee_head.amount + (fee_head.amount * fee_head.tax) / 100)
		.reduce((a, b) => a + b, 0) as number;
};

const Installment = ({ installmentData, installment_number, due_date }: any) => {
	return (
		<div className='installment'>
			<Table
				className=' hidden'
				heading={'Installment ' + installment_number}
				description={'Due Date: ' + new Date(due_date).toLocaleDateString('hi')}
				data={[
					['Fee Head', 'Amount', 'Tax%', 'Total Fee (with tax)'],
					...installmentData[due_date].map((installment: any) => [
						installment.name,
						'₹' + (installment.amount as number).toLocaleString('hi'),
						installment.tax,
						'₹' + ((installment.amount + (installment.amount * installment.tax) / 100) as number).toLocaleString('hi'),
					]),
				]}
				footer={
					<div
						className={`grid grid-cols-4 grid-header p-3 font-semibold m-5 bg-violet-100 rounded-lg text-violet-900 text-center text-lg`}
					>
						<div>Total Fee:</div>
						<div></div>
						<div></div>
						<div>₹{sum_installment(installmentData[due_date]).toLocaleString('hi')}</div>
					</div>
				}
			/>
		</div>
	);
};

// const fee_heads_to_installments = (fee_heads: any[]) => {
// 	var installments: any = {};
// 	fee_heads.forEach((fee_head) => {
// 		for (var i = 0; i < 12 / fee_head.frequency_months; i++) {
// 			var due_date = new Date(fee_head.start_date).setMonth(
// 				new Date(fee_head.start_date).getMonth() + i * fee_head.frequency_months,
// 			);
// 			if (installments[due_date]) {
// 				installments[due_date].push(fee_head);
// 			} else {
// 				installments[due_date] = [fee_head];
// 			}
// 		}
// 	});
// 	var sorted_installments: any = {};
// 	Object.keys(installments)
// 		.sort()
// 		.forEach((key) => {
// 			sorted_installments[key] = installments[key];
// 		});
// 	var installments_arr: any = [];
// 	for (const key in sorted_installments) {
// 		installments_arr.push({ due_date: key, fee_heads: installments[key] });
// 	}
// 	console.log({ installments_arr });
// 	return installments_arr;
// };

const get_installments = async (clas: any) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	let requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/fee-config/fee-strucutre/${clas}`, requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const FeeStrucureClassView = ({
	fee_heads,
	clas,
	category,
	gender,
}: {
	fee_heads: any[];

	clas: string;
	category: string;
	gender: string;
}) => {
	// const applicable_fee_heads = fee_heads.filter((fee_head) => {
	// 	return fee_head.class.includes(clas) && fee_head.category.includes(category) && fee_head.gender.includes(gender);
	// });
	// const installments = fee_heads_to_installments(applicable_fee_heads);
	// var annual_fee = 0;
	// installments.forEach((installment: any) => {
	// 	annual_fee += sum_installment(installment.fee_heads);
	// });

	const [installmentsData, setInstallmentsData] = useState<any>();
	const [installments, setInstallments] = useState<any>([]);
	const [annual_fee, setAnnualFee] = useState(0);
	// useEffect(() => {
	// 	(async () => {
	// 		const res = await get_installments(clas, gender, category);
	// 		if (res instanceof Error) {
	// 			console.log('something wrong');
	// 		} else {
	// 			var annual_fee = 0;
	// 			const due_dates = Object.keys(res);
	// 			const installmentData = res;
	// 			due_dates.map((due) => {
	// 				annual_fee += sum_installment(installmentData[due]);
	// 			});
	// 			setInstallments(due_dates);
	// 			setInstallmentsData(res);
	// 			setAnnualFee(annual_fee);
	// 		}
	// 	})();
	// }, [clas, category, gender]);

	// const getInstallments = async () => {
	// 	var myHeaders = new Headers();
	// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	// 	var requestOptions: RequestInit = {
	// 		method: 'GET',
	// 		headers: myHeaders,
	// 		redirect: 'follow',
	// 	};

	// 	return await (
	// 		await fetch(`http://api.edviron.com/api/fee-config/fee-strucutre/${clas.name}/${category.name}/${gender.name}`, requestOptions)
	// 	).json();
	// };
	// useEffect(() => {
	// 	(async () => {
	// 		const res = await getInstallments();
	// 		console.log(res);
	// 	})();
	// }, [clas, category, gender]);
	return (
		<LoadAndRender
			promise={async () => {
				const res = await get_installments(clas);
				if (res instanceof Error) {
					console.log('something wrong');
				} else {
					var annual_fee = 0;
					const due_dates = Object.keys(res);
					const installmentData = res;
					// eslint-disable-next-line array-callback-return
					due_dates.map((due) => {
						annual_fee += sum_installment(installmentData[due]);
					});
					setInstallments(due_dates);
					setInstallmentsData(res);
					setAnnualFee(annual_fee);
				}
			}}
			reload_upon_change={[clas, category, gender]}
		>
			<div className='fee-structure-class-view my-5 w-full'>
				{installments.map((installment: any, index: number) => (
					<Installment
						key={index}
						installmentData={installmentsData}
						installment_number={index + 1}
						due_date={installment}
					/>
				))}
				<div className='total total-annual-fee'>
					<div className='grid grid-cols-4 grid-header p-3 font-semibold mx-5 mt-5 bg-violet-100 rounded-lg text-violet-900 text-center text-lg'>
						<div>Total Annual Fee:</div>
						<div></div>
						<div></div>
						<div>₹{annual_fee.toLocaleString('hi')}</div>
					</div>
				</div>
			</div>
		</LoadAndRender>
	);
};

const FeeStructureTab = () => {
	const [fee_heads, set_fee_heads] = React.useState<any>([]);
	const [show_modal, set_show_modal] = React.useState(false);
	// const [install, set_install] = React.useState<any>([]);
	const [selected, set_selected] = React.useState(0);
	const { classes, user, school } = useContext(dashboardContext);

	const [selected_class, set_selected_class] = React.useState({
		name: classes[0],
	});
	const [selected_category, set_selected_category] = React.useState({
		name: 'General',
	});
	const [selected_gender, set_selected_gender] = React.useState({
		name: 'Male',
	});

	const [csv_link, set_csv_link] = useState<any>('');

	const [fee_structure, set_fee_structure] = useState({});
	const [show_fee_structure_modal, set_show_fee_structure_modal] = useState(false);
	const [printContent, setPrintContent] = useState<any>(null);

	useEffect(() => {
		const dues: any = {};

		fee_heads.forEach((feeHead: any) => {
			for (var i = 0; i < 12 / feeHead.frequency_months; i++) {
				var due = {
					name: feeHead.name,
					amount: feeHead.amount,
					fee_head_id: feeHead._id,
					tax: feeHead.tax,
					clas: feeHead.class,
				};
				const month = new Date(feeHead.start_date).getMonth() + i * feeHead.frequency_months;
				const year = new Date(feeHead.start_date).getFullYear();
				const date = new Date(
					year + Math.floor(month / 12),
					month % 12,
					school?.school?.fee_collection_date,
				).toString();
				// if(new Date(date)<new Date())
				dues[date] = dues[date] || [];
				dues[date].push(due);
			}
		});
		var sorted: any = {};
		Object.keys(dues)
			.sort((a, b) => (new Date(a) as any) - (new Date(b) as any))
			.forEach((key) => {
				sorted[key] = dues[key];
			});

		set_fee_structure(sorted);
	}, [fee_heads]);

	// const getInstallments = async () => {
	// 	var myHeaders = new Headers();
	// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	// 	var requestOptions: RequestInit = {
	// 		method: 'GET',
	// 		headers: myHeaders,
	// 		redirect: 'follow',
	// 	};

	const getInstallments = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/fee-config/fee-strucutre/${selected_class.name}/${selected_category.name}/${selected_gender.name}`,
			requestOptions,
		);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	// 	return await (
	// 		await fetch(
	// 			`http://api.edviron.com/api/fee-config/fee-strucutre/${selected_class}/${selected_category}/${selected_gender}`,
	// 			requestOptions,
	// 		)
	// 	).json();
	// };

	return (
		<Loading component={<FeeConfigSmallLoader />}>
			<LoadAndRender
				load={true}
				promise={async () => {
					set_fee_heads(await get_fee_heads());
					// set_install(await getInstallments());
				}}
			>
				<div className='fee-structure px-5 w-full'>
					<Modal
						children={
							<div className='create-fee-head mt-5 w-full'>
								<LoadAndRender promise={async () => {}} height='full'>
									<Form
										className='w-full grid grid-cols-2 gap-5'
										onSubmit={async (data: any) => {
											await create_fee_head({
												name: data['Fee Head Name'],
												fm: fee_type_string_to_frequency_months(data['Fee Type']),
												amt: data['Fee Amount'],
												classes: boolean_map_to_array(data['Class']),

												tax: data['Tax(%)'],
												start_date: data['Start Date'],
											});
											set_fee_heads(await get_fee_heads());
											set_show_modal(false);
										}}
									>
										<Input name='Fee Head Name' add_error={() => {}} required />
										<Input
											name='Fee Amount'
											type='number'
											prefix='₹'
											min={0}
											add_error={() => {}}
											onPaste={preventPasteNegative}
											onKeyDown={preventNegativeValues}
											required
										/>
										<Input
											name='Tax(%)'
											type='number'
											add_error={() => {}}
											onPaste={preventPasteNegative}
											onKeyDown={preventNegativeValues}
											min={0}
											maxAmount={100}
											required
										/>
										<Input
											type='date'
											name='Start Date'
											add_error={() => {}}
											// min={`${new Date().getFullYear()}-${
											// 	new Date().getMonth() > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)
											// }-${new Date().getDate()}`}
											// required
										/>
										<RadioInput
											name='Fee Type'
											add_error={() => {}}
											options={['Monthly', 'Bimonthly', 'Quarterly', 'Half Yearly', 'One Time']}
											required
										/>
										<CheckboxInput name='Class' add_error={() => {}} options={classes} required />
										{/* <CheckboxInput
											className='capitalize'
											name='Gender'
											options={['male', 'female', 'others']}
											required
										/>
										<CheckboxInput
											name='Category'
											options={['general', 'st', 'sc', 'obc'].map((e) => {
												if (e === 'general') return 'General';
												if (e === 'sc') return 'SC';
												if (e === 'st') return 'ST';
												if (e === 'obc') return 'OBC';
											})}
											required
										/> */}
										<div className='col-span-2 flex justify-center items-center'>
											<button
												type='submit'
												className=' ml-auto mx-auto max-w-[10rem] w-full rounded-md bg-[#6F6AF8] px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]'
											>
												Submit
											</button>
										</div>
									</Form>
								</LoadAndRender>
							</div>
						}
						title='Create Fee Head'
						open={show_modal}
						setOpen={set_show_modal}
						className='max-w-4xl w-full'
					/>
					<div className='flex  my-5 justify-between'>
						<Switch options={['Structure view', 'Class view']} selected={selected} set_selected={set_selected}></Switch>
						<div>
							{(user?.access === Access.SUPER || user.access === Access.ALL_ACCESS) && (
								<button
									className=' ml-auto m-2 border-2 border-violet-500 rounded-lg text-violet-500 font-medium py-2 px-5 cursor-pointer'
									onClick={() => {
										set_show_fee_structure_modal(true);
									}}
								>
									Download Fee Structure
								</button>
							)}
							{user.access === Access.SUPER ||
							user.access === Access.ALL_ACCESS ||
							user.access === Access.MANAGEMENT ? (
								<button
									onClick={() => {
										set_show_modal(true);
									}}
									className=' bg-[#6F6AF8] text-white px-10 py-2 rounded-md ml-auto'
								>
									Add Fee Head
								</button>
							) : (
								''
							)}
						</div>
					</div>
					{selected === 0 ? (
						<div className='grid grid-cols-2 gap-5 my-5'>
							{fee_heads.map((fee_head: any, i: any) => (
								<FeeHeadCard key={fee_head._id} fee_head={fee_head} set_fee_heads={set_fee_heads} />
							))}
						</div>
					) : (
						<div className='class-wise'>
							<div className='form flex gap-8'>
								<div className='w-1/4'>
									{classes.length > 0 && (
										<Select
											selected={selected_class}
											setSelected={set_selected_class}
											label='Select Class'
											options={classes.map((c: any) => {
												return { name: c };
											})}
										/>
									)}
								</div>
							</div>
							<FeeStrucureClassView
								category={selected_category.name}
								clas={selected_class.name}
								gender={selected_gender.name}
								fee_heads={fee_heads}
							/>
						</div>
					)}
				</div>

				<Modal
					title='Entire Fee Structure'
					className='max-w-6xl w-full'
					open={show_fee_structure_modal}
					setOpen={set_show_fee_structure_modal}
				>
					<FeeStructureModal setPrintContent={setPrintContent} fee_structure={fee_structure} />
					<DownloadPDF data={printContent} name='entire_fee_structure' title='Fee Structure' />
				</Modal>
			</LoadAndRender>
		</Loading>
	);
};

export default FeeStructureTab;
