import React from 'react';
import { Menu, MenuItem } from '../../../../components/HorizontalMenu/Menu';
import FeeStructureTab from './pages/FeeStructureTab/FeeStructureTab';
import { Routes, Route } from 'react-router-dom';
import FineTab from './pages/FineTab/FineTab';
import PrevSessionDuesTab from './pages/PrevSessionDuesTab/PrevSessionDuesTab';
import DiscountsTab from './pages/DiscountsTab/DiscountsTab';

const FeeConfig = () => {
	const [path, set_path] = React.useState<any>(window.location.pathname);
	return (
		<div className='fee-config w-full flex flex-col my-5'>
			<div className='menu mx-5'>
				<Menu path={path} set_path={set_path}>
					<MenuItem name='Fee Structure' to='/fee_config' />
					<MenuItem name='Fine' to='/fee_config/fine' />
					<MenuItem name='Offers & Discounts' to='/fee_config/discounts' />
					<MenuItem name='Previous Session Dues' to='/fee_config/prev_session_dues' />
				</Menu>
			</div>
			<div className=''>
				<Routes>
					<Route path='/' element={<FeeStructureTab />} />
					<Route path='fine' element={<FineTab />} />
					<Route path='discounts' element={<DiscountsTab />} />
					<Route path='prev_session_dues' element={<PrevSessionDuesTab />} />
				</Routes>
			</div>
		</div>
	);
};

export default FeeConfig;
