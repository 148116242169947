import React, { useEffect } from 'react';
import { useState } from 'react';
import Input from '../../../../../components/Input/Input';
import Select from '../../../../../components/Select/Select';
import { Access } from '../AccessEnum';
import { preventNegativeValues } from '../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../helper/preventPasteNegative';
import Form from '../../../../../components/Form/Form';
const options = ['Admin', 'Management Head', 'Management Staff'];
const getAccessFromRole = (role: string) => {
	if (role === options[0]) return Access.ALL_ACCESS;
	if (role === options[1]) return Access.MANAGEMENT;
	if (role === options[2]) return Access.STAFF;
};
const getRoleFromAccess = (access: Access) => {
	if (access === Access.ALL_ACCESS) return options[0];
	if (access === Access.MANAGEMENT) return options[1];
	if (access === Access.STAFF) return options[2];
};

const AddEditModal = ({ portalUser, setAdminData }: any) => {
	const [id, setId] = useState(portalUser.length ? portalUser[0]._id : '');
	const [name, setName] = useState(portalUser.length ? portalUser[0].name : '');
	const [email, setEmail] = useState(portalUser.length ? portalUser[0].email_id : '');
	const [mobileNumber, setMobileNumber] = useState(portalUser.length ? portalUser[0].phone_number : '');
	const [role, setRole]: [any, any] = useState(
		portalUser.length ? { name: getRoleFromAccess(portalUser[0].access) } : '',
	);
	// const [status, setStatus] = useState(portalUser.length ? 'Active' : '');
	// const [levelOfAccess, setlevelOfAccess] = useState(portalUser.length ? 'Super Admin' : '');

	useEffect(() => {
		if (portalUser.length) {
			setRole({ name: getRoleFromAccess(portalUser[0].access) });
			setAdminData({
				id: id,
				name: name,
				email_id: email,
				phone_number: mobileNumber,
				access: getAccessFromRole(role.name),
			});
		}
	}, []);
	useEffect(() => {
		setAdminData({
			id: id,
			name: name,
			email_id: email,
			phone_number: mobileNumber,
			access: getAccessFromRole(role.name),
		});
	}, [name, mobileNumber, role, email]);
	return (
		<div className='w-full'>
			<Form onSubmit={() => {}}>
				<Input
					name='Name'
					value={name}
					type='text'
					onChange={(name: any, value: any, e: any) => {
						setName(name);
					}}
					placeholder='Enter Employee Name'
					add_error={() => {}}
					required
				/>
				<Input
					name='Email'
					value={email}
					type='email'
					onChange={(name: any, value: any) => setEmail(name)}
					placeholder='Enter Employee Email Id'
					add_error={() => {}}
					required
				/>
				<Input
					name='Mobile Number'
					value={mobileNumber}
					onChange={(name: any, value: any) => setMobileNumber(name)}
					placeholder='Enter Mobile Number'
					add_error={() => {}}
					type='number'
					onKeyDown={preventNegativeValues}
					onPaste={preventPasteNegative}
					min={0}
					maxLength={10}
					required
				/>
				<Select
					label='Level of Access'
					selected={role}
					setSelected={setRole}
					options={options.map((c) => {
						return { name: c };
					})}
				/>
			</Form>
		</div>
	);
};

export default AddEditModal;
